<template>
<div>
    <v-card elevation="2" style="border-bottom: inset; border-top: outset" width="100%">
        <v-card-title style="margin-top: -10px; margin-bottom: -20px">
            <h6>{{title}}</h6>
            <v-spacer></v-spacer>
            <slot name="options"> </slot>
            <v-tooltip bottom="" v-if="add && $fun.getSecurity().IsAllowAdd">
                <template v-slot:activator="{ on }">
                    <v-btn style="margin-top:-8px" text v-on="on" small append elevation="0" @click="$emit('add')">
                        <v-icon style="font-size:18px;">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>
            <v-tooltip bottom="" v-if="edit && $fun.getSecurity().IsAllowEdit">
                <template v-slot:activator="{ on }">
                    <v-btn style="margin-top:-8px" text small v-on="on" @click="$emit('edit')">
                        <v-icon style="font-size:14px;">mdi-file-edit-outline</v-icon>
                    </v-btn>
                </template>
                <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom="" v-if="remove && $fun.getSecurity().IsAllowDelete">
                <template v-slot:activator="{ on }">
                    <v-btn style="margin-top:-8px" text small v-on="on" @click="$emit('delete', 0)">
                        <v-icon style="font-size:18px;">mdi-minus-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span>Eliminar</span>
            </v-tooltip>

            <v-tooltip bottom v-if="clear">
                <template v-slot:activator="{ on }">
                    <v-btn style="margin-top:-8px" text v-on="on" small @click="$emit('clear')" elevation="0">
                        <v-icon style="font-size:18px;"> mdi-broom</v-icon>
                    </v-btn>
                </template>
                <span>Limpiar</span></v-tooltip>

            <v-tooltip bottom="" v-if="importfile">
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" small @click="$emit('importfile')" elevation="0">
                        <v-icon style="font-size:18px;">mdi-file-import</v-icon>
                    </v-btn>
                </template>
                <span>Importar</span>
            </v-tooltip>

        </v-card-title>
    </v-card>
</div>
</template>

<script>
export default {
    name: "",
    props: {
        title: {
            type: String,
            default: ""
        },
        add: {
            type: Boolean,
            default: false,
        },
        clear: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        importfile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },

};
</script>

<style lang="scss" scoped></style>
