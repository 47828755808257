<template>
<div>
    <vc-crud  v-if="show == true" @doubleClick="doubleClick($event)" close @close="$emit('close')" noBorder noDark searchInput title="Listado de Personas" :filter="filterGrid" :config="this.config">
    </vc-crud>
</div>
</template>

<script>
import ServicePrs from "@/services/General/GenPersonService";
export default {
    components: {},
    props: {
        typeperson: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        show: false,
        filterGrid: {
            TypePerson: 1,
            SecStatus: 1
        },
        config: {
            model: {
                PrsID: "ID",
                PrsDocumentNumber: "string",
                PrsFullName: "string",
                TypePersonDocument: "int",
                SecStatus: "",
            },
            service: ServicePrs,
            headers: [{
                    text: "ID",
                    value: "PrsID"
                },
                {
                    text: "N°Documento",
                    value: "PrsDocumentNumber"
                },
                {
                    text: "Nombre",
                    value: "PrsFullName"
                },
            ],
        },
    }),
    methods: {
        doubleClick(item) {
            this.$emit("returnPerson", item);
        },
    },
    created() {
        this.config.typeperson = this.typeperson;
        this.show = true;
    },
};
</script>
