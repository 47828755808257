<template>
<div style="box-shadow: 1px 3px 4px 0px #9c9898;border-radius: 20px;">

    <v-footer style="border-radius: 5px; " dense flat class="body-2 font-weight-bold" :color="'#f5f5f5'">

        <!-- :color="'#f5f5f5'" -->
        <!-- <v-btn elevation="0" class="capitalize" text color="">
            {{title}}
        </v-btn> -->
        <h5 class="pt-1 capitalize"> {{title}}</h5>

        <v-spacer></v-spacer>
        <slot name="options"> </slot>

        <!-- //Inicio Botones -->
        <!-- <v-tooltip bottom="" v-if="add && $fun.getSecurity().IsAllowAdd">
            <template v-slot:activator="{ on }"> -->
        <v-btn v-if="add && $fun.getSecurity().IsAllowAdd" text icon small append elevation="0" @click="$emit('add')" class="ml-1 mr-1 capitalize">
            <v-icon class="mr-0" style="font-size:14px;">fa-plus</v-icon>

        </v-btn>
        <!--  </template>
            <span>Agregar</span>
        </v-tooltip>
 -->

        <v-divider vertical></v-divider>

        <v-tooltip bottom="" v-if="save && $fun.getSecurity().IsAllowSave">
            <template v-slot:activator="{ on }">
                <v-btn icon text small v-on="on" class="capitalize save ml-1 mr-1" @click="$emit('save')">
                    <v-icon style="font-size:16px;">mdi-content-save</v-icon>
                </v-btn>
            </template>
            <span>Guardar</span>
        </v-tooltip>

        <!--   <v-tooltip bottom="" v-if="edit && $fun.getSecurity().IsAllowEdit">
            <template v-slot:activator="{ on }">
 -->
        <v-btn v-if="edit && $fun.getSecurity().IsAllowEdit" icon text small @click="$emit('edit')" class="ml-1 mr-1 capitalize">
            <v-icon class="mr-0" style="font-size:12px;">fa-pencil</v-icon>

            <!-- mdi-file-edit-outline -->
        </v-btn>
        <!-- </template>
            <span>Editar</span>
        </v-tooltip> -->

        <div v-if="item !== null && $fun.getSecurity().IsAllowDelete">
            <v-tooltip bottom="" v-if="restore && item.SecStatus == 0">
                <template v-slot:activator="{ on }">
                    <v-btn icon text small v-on="on" @click="$emit('delete', 1)" class="ml-1 mr-1">
                        <v-icon style="font-size:18px;">mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span>Habilitar</span>
            </v-tooltip>

            <!-- && item.SecStatus == 1 -->

            <!-- <v-tooltip bottom="" v-if="remove">
                <template v-slot:activator="{ on }"> -->
            <v-btn v-if="remove" icon text small @click="$emit('delete', 0)" class="ml-1 mr-1 capitalize">
                <!-- <v-icon style="font-size:16px;">fa-xmark</v-icon> -->
                <v-icon class="mr-0" style="font-size:16px;">mdi-close</v-icon>

                <!-- mdi-close -->
            </v-btn>
            <!--  </template>
                <span>Eliminar</span>
            </v-tooltip> -->
        </div>

        <v-tooltip bottom="" v-if="view && $fun.getSecurity().IsAllowView">
            <template v-slot:activator="{ on }">
                <v-btn icon text small v-on="on" @click="$emit('view')" class="ml-1 mr-1">
                    <v-icon style="font-size:14px;">far fa-eye</v-icon>
                </v-btn>
            </template>
            <span>Ver</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="close">
            <template v-slot:activator="{ on }">
                <v-btn icon text v-on="on" small @click="$emit('close')" elevation="0" class="ml-1 mr-1">
                    <v-icon style="font-size:18px;">mdi-close</v-icon>
                </v-btn>
            </template>
            <span>Cerrar</span>
        </v-tooltip>

        <!-- //Inicio Botones -->

        <!--  //Fin Botones -->

        <!-- <div v-if="search">
            <v-menu v-if="!searchInput" v-model="menuSearch" :close-on-content-click="false" offset-x>
                <template v-slot:activator="{ on, attrs }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ ons }">
                            <div v-on="ons" style="display:inline;">
                                <v-btn text small v-on="on" v-bind="attrs">
                                    <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span>Buscar</span>
                    </v-tooltip>
                </template>
                <v-card rounded="">
                    <v-list>
                        <v-col style="padding-top:0px !important;padding-bottom:0px !important;">
                            <v-text-field label="Buscar" style="margin:5px;" class="s-toolbar-search" ref="buscar" v-model="searchText" :append-icon="searchBottom ? '' : 'mdi-magnify'" autofocus="" outlined dense clearable hide-details>
                                <template v-slot:prepend>
                                    <v-btn icon x-small v-if="searchBottom" @click="getAnswer()">
                                        <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-list>
                </v-card>
            </v-menu>
        </div>
        <div>
            <v-text-field v-if="searchInput" style="margin-top:-1px;width:150px !important;" ref="buscar" height="30" class="" width="60" :append-icon="searchBottom ? '' : 'mdi-magnify'" v-model="searchText" dense clearable hide-details>
                <template v-slot:append>
                    <v-btn icon x-small v-if="searchBottom" @click="getAnswer()">
                        <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </div> -->

    </v-footer>
</div>
</template>

<script>
export default {
    name: "vcFooter",
    props: {
        title: {
            type: String,
            default: "Vuetify Confirm Dialog Example",
        },

        noDark: {
            type: Boolean,
            default: true,
        },
        close: {
            type: Boolean,
            default: false,
        },
        searchInput: {
            type: Boolean,
            default: false,
        },
        searchBottom: {
            type: Boolean,
            default: false
        },
        search: {
            type: Boolean,
            default: false,
        },

        //Eventos
        save: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        restore: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Boolean,
            default: false,
        },

        entity: {
            type: Object,
            default: null,
        },

        //Transacciones
        approve: {
            type: Boolean,
            default: false,
        },
        transactionApproved: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        menuSearch: "",
        searchText: "",
        item: {
            SecStatus: 1
        }
    }),
    mounted() {},
    methods: {
        getAnswer() {
            if (this.searchText == null) this.searchText = "";
            this.$emit("search", this.searchText);
        },

        approved() {
            this.$emit("approved");
        },

        disapproved() {
            this.$emit("disapproved");
        }
    },
    watch: {
        searchText() {
            if (!this.searchBottom)
                this.debouncedGetAnswer();
        },
        entity() {
            this.item = this.entity
            /* console.log("entityentityentityentity", this.item, this.restore) */
        },
    },
    computed: {
        colorToolbar() {
            return localStorage.getItem('colorToolbar');
        }
    },
    created() {
        this.item = this.entity
        /*  console.log("entity al levantar", this.item, this.restore) */
        this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
    },
};
</script>

<style lang="scss" scoped></style>
