<template>
<div>
    <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="errors" @close="processing=false;messageProcessing=''" />
    <v-card class="mt-2">
        <!-- <s-toolbar style="font-family: Calibri" :label="'Adjuntar Files - ' + item.CttDocumentNumber" dark :save="Files == null" @save="save()" close @close="$emit('close');">
            <v-divider vertical></v-divider>
        </s-toolbar> -->

        <vc-header :title="'Files - Nro. Orden: ' + item.OrdDocumentNumber" close @close="$emit('close')" save @save="save()"></vc-header>

        <!-- <v-divider></v-divider> -->

        <!-- Cuando es Edicion -->
        <!-- <v-card outlined class="mt-3" v-if="Files !== null">
            <v-row style="margin:auto; margin-top:10px; margin-bottom: 10px;">
                <v-col lg="12" cols="12" class="s-col-text">
                    <h5>Sustentos</h5>
                </v-col>
                <v-col cols="12" class="s-col-text">
                    <v-card elevation="3">
                        <v-row style="margin:auto">
                            <v-col cols="12" v-for="(item, index) in itemAttach" :key="item.RqdID">

                                <vc-text :rules="rules" :label="item.RqdDescription" readonly v-model="item.Attach.name" :outlined="false">
                                    <template slot="append">
                                        <v-switch v-model="item.RqdAcceptFile" disabled :color="item.RqdAcceptFile == 1 ? 'success' : 'error'" hide-details style="margin-top:-5px" class="pl-6" label="Correcto"></v-switch>
                                    </template>

                                    <template slot="prepend">
                                        <v-icon v-if="item!==null" color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                    </template>
                                </vc-text>

                                <v-file-input :rules="rules" show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.Attach">
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card> -->

        <v-card outlined class="mt-3">
            <v-row style="margin:auto; margin-top:10px; margin-bottom: 10px;">

                <v-col lg="12" cols="12" class="s-col-text">
                    <h5>Sustentos Requeridos</h5>

                </v-col>
                <v-col cols="12" class="s-col-text">
                    <v-card elevation="3">
                        <v-row style="margin:auto">
                            <v-col cols="12">
                                <vc-text ref="NroComp" label="Nro. Comprobante" autofocus v-model="NroComp"></vc-text>
                            </v-col>
                            <!-- <v-divider></v-divider> -->
                            <v-col cols="4" v-for="(item, index) in itemAttach" :key="item.RqdID">

                                <v-file-input persistent-hint :disabled="item.RqdAcceptFile" style="margin-bottom:-20px" :show-size="false" :rules="rules" :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                    <!-- <template slot="append">
                                        <v-icon v-if="Files" color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                        <v-switch v-if="Files && item.RqdAcceptFile" v-model="item.RqdAcceptFile" disabled :color="item.RqdAcceptFile ? 'success' : 'error'" hide-details style="margin-top:-15px" class="pl-3" label="Correcto"></v-switch>
                                    </template> -->
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

            </v-row>
        </v-card>

        <v-card outlined class="mt-3">
            <v-row style="margin:auto; margin-top:10px; margin-bottom: 10px;">
                <v-col cols="12" class="s-col-text">
                    <v-card elevation="3">
                        <v-row style="margin:auto">
                            <v-col cols="12">
                                <v-data-table group-by="NroComp" dense :items-per-page="-1" disable-pagination hide-default-footer :headers="headers" :items="itemAttachReceived">
                                    <!-- <template v-slot:item.edit="{ item }">
                                        <a class="ml-0" style="text-decoration:underline" @click="editFile(item)">Editar</a>
                                    </template> -->
                                    <template v-slot:item.RqdAcceptFile="{ item }">
                                        <v-chip x-small color="error" v-if="item.RqdAcceptFile">{{item.RqdAcceptFile ? 'Por Corregir' : ''}}</v-chip>
                                    </template>
                                    <template v-slot:item.RqdAttach="{ item }">
                                        <label v-if="!item.RqdAcceptFile">
                                            <v-icon color="green" @click="openFile({name:item.RqdAttach})">mdi-download</v-icon>{{item.RqdAttach}}
                                        </label>
                                        <v-file-input v-if="item.RqdAcceptFile" style="margin-bottom:-20px" :ref="'attach'+item.Id" :accept="item.RqdAccept" v-model="item.Attach">
                                            <template slot="append">
                                                <a class="ml-0" style="text-decoration:underline" @click="editFile(item)">Grabar</a>
                                            </template>
                                        </v-file-input>

                                    </template>
                                </v-data-table>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>

        <!--  <v-card outlined v-if="Files == null">
                <v-row style="margin-top:-30px; margin-bottom: 10px">
                    <v-col lg="12" cols="12" class="s-col-text">
                        <h5>Sustentos Requeridos</h5>
                    </v-col>

                    <v-col lg="12" cols="12" class="s-col-text">

                        <v-row>
                            <v-col cols="6" v-for="(item, index) in itemAttach" :key="item.RqdID">
                                <v-file-input :rules="rules" show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                </v-file-input>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
        </v-card> -->
    </v-card>
</div>
</template>

<script>
import _sCotization from "@/services/Logistics/LgsCotizationService";
import _sGeneral from "@/services/HelperService.js";

export default {
    name: '',
    props: {
        item: null,
    },
    data() {
        return {
            //Loading
            processing: false,
            messageProcessing: "",
            errors: [],
            itemAttach: [],
            itemAttachReceived:[],
            Files: null,
            rules: [
                value => !value || value.size < 2000000 || 'El tamaño del archivo debe ser inferior a 2 MB!',
            ],
            sizeAttach: 2000000,

            headers: [{
                    text: "Nro. Comp.",
                    value: "NroComp"

                },
                /* {
                    text: "edit",
                    value: "edit"

                }, */
                {
                    text: "Grupo",
                    value: "RqdGroup"

                },
                {
                    text: "Descripcion",
                    value: "RqdDescription"

                },
                {
                    text: "Archivo",
                    value: "RqdAttach"

                },
                {
                    text: "Estado",
                    value: "RqdAcceptFile"

                },
                {
                    text: "Observacion",
                    value: "RqdObservations"

                }
            ],
            items: [],
            NroComp: null
        };
    },

    created() {
        this.itemAttach = []
        if (this.item.itemsAttachSupport_.length > 0) {
            this.item.itemsAttachSupport_.forEach(element => {

                /*  element.Attach = null
                 element.Attach = null */
                element.Attach = {
                    name: element.RqdAttach
                };

                /* if (element.RqdAttach == null) {
                    element.Attach = null;
                } */
            });

            
            //this.Files = this.itemsAttachSupport_[0].Files

        }

        this.itemAttach = this.item.itemsAttachSupport
        this.itemAttachReceived = this.item.itemsAttachSupport_
    },

    mounted() {

    },

    methods: {

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 7).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        save() {

            if (this.NroComp == null || this.NroComp.length <= 0) {
                this.$refs.NroComp.error("Ingrese Nro. Comprobante.")
                return
            }

            /*  this.itemAttach.forEach(element => {
                 if (element.RqdAttach == null && element.Attach !== null) {
                     element.RqdAttach = element.Attach
                 }
             }); */

            console.log("item", this.itemAttach)

            /*    */

            /*  //nuevo
             if (this.Files == null) {
                 this.itemAttach.forEach(element => {
                     if (element.RqdAttach == null && element.Attach !== null) {
                         element.RqdAttach = element.Attach
                     }
                 });

                 obj = this.itemAttach
             }

             //Edicion
             if (this.Files == 1) {

                 this.itemAttach.forEach(element => {
                     if (element.Attach !== null) {
                         if (element.RqdAttach !== element.Attach.name) {
                             element.RqdAttach = element.Attach
                             obj.push(element)

                         }
                     }
                 });
             } */

            this.$fun.alert("¿Seguro de cargar archivos?", "question").then(val => {
                if (val.value) {

                    this.$fun.uploadFiles(this.itemAttach, 7).then(resp => {

                            resp.data.forEach((element) => {
                                element.SreID = this.itemAttach[0].SreID;
                                element.OrdID = this.itemAttach[0].OrdID;
                                element.NroComp = this.NroComp
                            });

                            console.log("ddddddddddddddddddd", resp.data)

                            this.messageProcessing = ""
                            this.processing = true

                            _sCotization.savefiles(resp.data, this.$fun.getUserID()).then((x) => {
                                    if (x.status == 200) {
                                        this.processing = false;
                                        this.$fun.alert(
                                            "Datos guardados correctamente",
                                            "success",
                                            false,
                                            "center"
                                        );
                                        this.$router.go(0);
                                    }
                                },
                                (e) => {
                                    this.messageProcessing = " ";
                                    this.errors = this.messageProcessing
                                    this.processing = false;
                                });
                        },
                        (e) => {
                            this.messageProcessing = " ";
                            this.errors = this.messageProcessing
                            this.processing = false;
                        });
                }
            })

        },

        editFile(item) {

            if (item == null) {
                return
            }

            if (item.Attach == null) {
                this.$fun.alert("No se ha cargado archivo.", "warning")
                return
            }

            item.RqdAttach = item.Attach

            this.$fun.alert("¿Seguro de cargar archivo?", "question").then(val => {
                if (val.value) {
                    this.$fun.uploadFiles([item], 7).then(resp => {

                            //item.RqdAttach = 

                            resp.data.forEach((element) => {
                                element.Rcd = item.Rcd;
                            });

                            console.log("resp.dataresp.data", resp.data)

                            this.messageProcessing = ""
                            this.processing = true

                            _sCotization.savefiles(resp.data, this.$fun.getUserID()).then((x) => {
                                    if (x.status == 200) {
                                        this.processing = false;
                                        this.$fun.alert(
                                            "Archivo actualizado correctamente",
                                            "success",
                                            false,
                                            "center"
                                        );
                                        this.$router.go(0);
                                    }
                                },
                                (e) => {
                                    this.messageProcessing = " ";
                                    this.errors = this.messageProcessing
                                    this.processing = false;
                                });
                        },
                        (e) => {
                            this.messageProcessing = " ";
                            this.errors = this.messageProcessing
                            this.processing = false;
                        });
                }

            })
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
