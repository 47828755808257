<template>
<div class="page-title-wrapper">
    <!--  <v-row style="margin: auto;margin-top:-25px">
        <v-card width="100%" height="25px" dense style="background: ; margin-left: 0px" elevation="0">
            <i class="pe-7s-home" style="margin-top: 6px; margin-left: 10px;"> </i>
            <v-breadcrumbs :items="items" class="px-7 py-1" style="margin-top: -24px; font-size: 10px; font-family: Calibri">
                <template v-slot:divider>
                    <i class="pe-7s-angle-right-circle"> </i>
                </template>
            </v-breadcrumbs>
        </v-card>
    </v-row> -->
    <v-row justify="center">
        <v-breadcrumbs :items="items" class="px-2 py-0">
            <template v-slot:divider>
                <i class="pe-7s-angle-right-circle"> </i>
            </template>
        </v-breadcrumbs>
    </v-row>

    <!--     <v-row style="margin: auto;margin-top:-25px">
        <v-card width="100%" height="25px" dense  elevation="0">
            <i class="pe-7s-home" style="margin-top: 6px; margin-left: 10px;"> </i>

        </v-card>
    </v-row> -->

</div>
</template>

<script>
export default {
    components: {},

    watch: {
        $route() {
            this.items = this.$route.meta.breadcrumb;
        },
    },
    mounted() {
        this.items = this.$route.meta.breadcrumb;

    },
    data() {
        return {
            items: [],
        };
    },
}
</script>
