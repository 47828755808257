<template>
<div>
    <v-card class="mt-2">

        <vc-toolbar style="font-family: Calibri;" title="Registro de Cotización" dark close @close="$emit('close')">
            <v-divider vertical></v-divider>
        </vc-toolbar>

        <v-card outlined>
            <v-col>
                <form id="formsuppliercotization">
                    <v-row>
                        <v-col cols="12">

                            <v-card outlined width="100%" style="margin-top: -10px">
                                <v-card-title style="margin-top: -15px;margin-bottom: -10px;font-family: Calibri;font-size: ;">
                                    <h4>Datos Generales</h4>
                                </v-card-title>

                                <v-row>
                                    <v-row style="margin-top:0px">
                                        <v-col lg="3" md="3" cols="6" class="text-center">
                                            Fecha Inicio
                                            <h1 style="transform: scale(0.5);margin-top:-10px">
                                                {{$moment(cotizationLocal.CttDateInitial).format($const.FormatDateTimeDB)}}
                                            </h1>

                                        </v-col>
                                        <v-col lg="3" md="3" cols="6" class="text-center">

                                            Fecha Fin
                                            <h1 style="transform: scale(0.5);margin-top:-10px">
                                                {{$moment(cotizationLocal.CttDateEnd).format($const.FormatDateTimeDB)}}
                                            </h1>
                                        </v-col>
                                        <v-col lg="3" md="3" cols="6" class="text-center" style="margin-top:-10px">
                                            Tiempo Restante
                                            <h1 style="transform: scale(0.8);">{{cotizationLocal.CttTimeLeft}}</h1>
                                        </v-col>
                                        <v-col lg="3" md="3" cols="6" class="text-center" style="margin-top:-10px">
                                            Tiempo Transcurrido
                                            <h1 style="transform: scale(0.8);">{{cotizationLocal.CttTimeElapsed}}</h1>
                                        </v-col>
                                    </v-row>
                                </v-row>

                            </v-card>

                            <v-card outlined width="100%" style="margin-top: 10px">
                                <v-card-title style="margin-top: -15px;margin-bottom: -10px;font-family: Calibri;font-size: ;">
                                    <h4>Condiciones</h4>
                                </v-card-title>
                                <v-row style="margin: auto;margin-top: -20px">
                                    <v-col cols="6" lg="3" md="3">
                                        <vc-select-definition id="TypeCredit" full :autocomplete="true" label="Credito" :def="1021" return-object v-model="objTypeCredit"></vc-select-definition>
                                    </v-col>

                                    <v-col cols="6" lg="3" md="3">
                                        <vc-select-definition id="TypePayment" full :autocomplete="true" label="Forma de Pago - Adelanto" return-object :def="1022" v-model="objTypePayment"></vc-select-definition>
                                    </v-col>

                                    <v-col cols="6" lg="2" md="2">
                                        <vc-text id="PrvDeliveryTime" number :min="1" label="Tiempo de Entrega(días)" v-model="orderLocal.PrvDeliveryTime"></vc-text>
                                    </v-col>

                                    <v-col cols="6" lg="2" md="2">
                                        <vc-text id="PrvTimeGarantia" number :min="0" label="Garantia(días)" v-model="orderLocal.PrvTimeGarantia"></vc-text>
                                    </v-col>
                                    <v-col cols="6" lg="2" md="2" class="">
                                        <vc-select-definition id="TypeCurrency" label="Moneda" :def="1020" v-model="orderLocal.TypeCurrency"></vc-select-definition>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card outlined width="100%" style="margin-top: 10px">
                                <v-card-title style="
                        margin-top: -10px;
                        margin-bottom: -10px;
                        font-family: Calibri;
                      ">
                                    <h4>Items Requeridos.</h4>
                                    <b style="color: red" class="ml-1 mr-1" v-if="item.CttRequiredVisit == 1">
                                        Cotización requiere Visita a Campo.</b>
                                    <b v-if="item.CttRequiredVisit == 1">
                                        <label v-if="item.CtpVisitAproved !== null">[Autorizado]</label>
                                        <label v-if="item.CtpVisitAproved == null">[No Autorizado]</label>
                                    </b>
                                    <v-spacer></v-spacer>
                                    <v-tooltip>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" small @click="openDialogImportExcel()" elevation="0" color="info">
                                                <v-icon style="font-size:18px;">mdi-file-import</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Importar</span>
                                    </v-tooltip>
                                    <v-btn class="ml-3" elevation="0" small @click="save()" color="info">Grabar</v-btn>
                                </v-card-title>
                                <v-card-actions>
                                    <v-card outlined width="100%" style="margin-top: -15px">
                                        <v-data-table class="table-condensed table-bordered" outlined disable-sort dense hide-default-footer :items-per-page="-1" :headers="headers" :items="items" v-model="selected" @click:row="rowClick" item-key="CtdID">
                                            <template v-slot:item.space="props">
                                                <v-chip x-small color="warning"></v-chip>
                                            </template>

                                            <template v-slot:item.OddQuantity="props">
                                                <vc-text @input="changeItem(props.item)" decimal v-model="props.item.OddQuantity"></vc-text>
                                            </template>
                                            <template v-slot:item.OddUnitPrice="props">
                                                <vc-text @input="changeItem(props.item)" decimal v-model="props.item.OddUnitPrice"></vc-text>
                                            </template>
                                            <template v-slot:item.EtdObservation="{ item }">
                                                <vc-text :maxlength="100" v-model="item.EtdObservation"></vc-text>
                                            </template>

                                            <template v-slot:item.OddTaxAmount="{ item }">
                                                {{
                          item.OddTaxAmount > 0
                            ? item.OddTaxAmount.toFixed(2)
                            : item.OddTaxAmount
                        }}
                                            </template>

                                            <template v-slot:item.OddAmount="{ item }">
                                                {{
                          item.OddAmount > 0 ? item.OddAmount.toFixed(2) : item.OddAmount
                        }}
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-card-actions>
                            </v-card>

                            <v-card outlined width="100%" style="margin-top: 10px">
                                <v-col style="padding-top: 24px">
                                    <v-row>
                                        <v-col lg="8" cols="12">
                                            <v-row>
                                                <v-col class="s-col-text">
                                                    <v-textarea :maxlength="100" auto-grow label="Observaciones Generales" v-model="orderLocal.PrvObservation" />
                                                    <span style="font-size: 9px; color: red">Max {{ "100" }} Caracteres -
                                                        {{
                            orderLocal.OrdObservation !== undefined &&
                            orderLocal.OrdObservation !== null
                              ? orderLocal.OrdObservation.length
                              : ""
                          }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col lg="4">

                                            <vc-select-definition label="Impuesto" return-object :def="1027" v-model="tax" @input="changeTax($event)" />

                                            <v-divider></v-divider>
                                            <v-row>
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>V. NETO</b></v-label>
                                                </v-col>
                                                <v-col lg="6" cols="6" class="s-col-form">
                                                    <h4 class="text-right">
                                                        <!-- {{orderLocal.OrdTotalNeto}} -->
                                                        <h4 class="text-right">
                                                            {{
                              orderLocal.OrdTotalNeto >= 1000
                                ? orderLocal.OrdTotalNeto.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : orderLocal.OrdTotalNeto > 0 ? parseFloat(orderLocal.OrdTotalNeto).toFixed(2) : 0
                            }}
                                                        </h4>
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-divider></v-divider>
                                            <v-row>
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label class="text-left"><b>COSTOS DIRECTOS</b></v-label>
                                                </v-col>
                                                <v-col lg="6" cols="6" class="s-col-form" style="margin-top:-15px">
                                                    <h4 class="text-right">
                                                        <vc-text decimal v-model="orderLocal.OrdDirectCosts" @input="changeTotal()"></vc-text>
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>GTO GENERALES</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form" style="margin-top:-15px">
                                                    <h4 class="text-right">
                                                        <vc-text decimal v-model="orderLocal.OrdGeneralgto" @input="changeTotal()"></vc-text>
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>UTILIDADES</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form" style="margin-top:-15px">
                                                    <h4 class="text-right">
                                                        <vc-text decimal v-model="orderLocal.OrdUtilities" @input="changeTotal()"></vc-text>
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>DESCUENTO</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form" style="margin-top:-15px">
                                                    <h4 class="text-right">
                                                        <vc-text decimal v-model="orderLocal.OrdDiscount" @input="changeTotal()"></vc-text>
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="mt-2 mb-0"></v-divider>
                                            <v-row>
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>SUB TOTAL</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form">
                                                    <h4 class="text-right">
                                                        {{
                            orderLocal.OrdSubTotal >= 1000
                              ? orderLocal.OrdSubTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdSubTotal > 0 ? parseFloat(orderLocal.OrdSubTotal).toFixed(2) : 0
                          }}
                                                    </h4>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col class="s-col-form">
                                                    <v-label><b>IMPUESTO{{ " (" + orderLocal.OrdTaxPercentage + "%)" }}</b></v-label>
                                                </v-col>
                                                <v-col class="s-col-form">
                                                    <h4 class="text-right">
                                                        {{
                            orderLocal.OrdTaxAmount >= 1000
                              ? orderLocal.OrdTaxAmount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTaxAmount >0  ? parseFloat(orderLocal.OrdTaxAmount).toFixed(2) : 0
                          }}
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="mt-2 mb-0"></v-divider>
                                            <v-row>
                                                <v-col class="s-col-form">
                                                    <v-label><b>TOTAL</b></v-label>
                                                </v-col>
                                                <v-col class="s-col-form">
                                                    <h4 class="text-right">
                                                        {{
                            orderLocal.OrdTotal >= 1000
                              ? orderLocal.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotal > 0 ? parseFloat(orderLocal.OrdTotal).toFixed(2) : 0
                          }}
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-card>

                            <v-card outlined width="100%" style="margin-top: 10px">
                                <v-card-title style="
                        margin-top: -10px;
                        margin-bottom: -10px;
                        font-family: Calibri;
                      ">
                                    <v-col cols="12">
                                        Favor de adjuntar Cotizacion. (Max. 100 Caracteres)
                                        <br />
                                        <b>(El nombre del archivo debe tener la siguiente estructura)</b>
                                        <b style="color: red">Ejem: COT-Nro.Cotización-Nro.Ruc-Nombre de Archivo</b>
                                        <br />
                                        {{
                          item.CttDocumentNumber +
                          "-" +
                          $fun.getUserName() +
                          "-Nombre archivo"
                        }}
                                        <v-file-input 
                                            v-for="(item, index) in itemsAttach"
                                            :key="item.RqdID"
                                            v-model="item.RqdAttach"
                                        show-size counter
                                        truncate-length="100" ref="myidcotizacion" :accept="'.pdf'" label="Adjuntar Cotizaciòn" />
                                    </v-col>
                                </v-card-title>
                            </v-card>
                            <!-- </v-card> -->
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-card>
    </v-card>

    <!-- modal para importar -->
    <v-dialog
        v-if="dialogImportExcel"
        v-model="dialogImportExcel"
        width="1000"
    >
        <lgs-cotization-detail-import
            @getDataExcel="getDataExcel"
        ></lgs-cotization-detail-import>
    </v-dialog>

</div>
</template>

<script>
//Services

import _sCotization from "@/services/Logistics/LgsCotizationService";
import _validaERP from '@/plugins/validaERP';
import _AttachConfiguration from "@/services/General/GenAttachConfigurationService";

import LgsCotizationDetailImport from './LgsCotizationDetailImport.vue';

export default {
    components: {LgsCotizationDetailImport},

    props: {
        item: null,
        user: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {
            dialogImportExcel : false,
            attach1 : {},
            itemsAttach : [],

            headers: [{
                    text: "Description",
                    value: "CtdDescription",
                },
                {
                    text: "Cantidad",
                    value: "CtdQuantity",
                    width: 80,
                },
                {
                    text: "Uni. M",
                    value: "UnitMeasurement",
                    width: 80,
                },
                {
                    text: "Comentario",
                    value: "CtdObservation",
                },
                {
                    text: "",
                    value: "space",
                    with: 10,
                },
                {
                    text: "Cant Stock.",
                    value: "OddQuantity",
                    width: 100
                },

                {
                    text: "P. Uni. (Sin IGV)",
                    value: "OddUnitPrice",
                    width: 100
                },
                {
                    text: "V. Neto",
                    value: "OddAmount",
                    width: 80,
                },
                {
                    text: "Comentario",
                    value: "EtdObservation",
                    width: 300,
                },
            ],
            items: [],
            selected: [],
            cotizationLocal: {},
            orderLocal: {
                PrvTimeGarantia: 1,
                PrvDeliveryTime: 1
            },
            tax: null,
            objTypeCredit: null,
            objTypePayment: null,
        };
    },

    computed: {},

    created() {

        this.changeTotal();
        this.initialize();
        this.getConfigFiles();
    },

    mounted() {},

    watch: {},

    methods: {
        getDataExcel(items)
        {
            console.log('data iniital ', this.items);
            console.log('data excel ', items);
            this.items.forEach(element => {
                var found = items.find(el => {
                    return element.CtdDescription == el["DESCRIPCION"]
                });
                if(found != null)
                {
                    element.OddQuantity = found["CANTIDAD"];
                    element.OddUnitPrice = found["PRECIO UNIDAD SIN IGV"];
                    element.EtdObservation = found["COMENTARIO"];
                    element.OddAmount = element.OddQuantity*element.OddUnitPrice;
                    this.changeItem(element);
                }
                console.log('element ', element);
            });
            this.dialogImportExcel = false;
        },

        openDialogImportExcel()
        {
            this.dialogImportExcel = true;
        },

        initialize() {
            _sCotization
                .byID({
                        CttID: this.item.CttID,
                    },
                    this.$fun.getUserID()
                )
                .then((resp) => {
                    if (resp.status == 200) {
                        resp.data.Result.itemsDetails.forEach((element) => {
                            element.OddQuantity = element.CtdQuantity;
                        });
                        this.cotizationLocal = resp.data.Result
                        console.log("AL LEVANTAR",this.cotizationLocal);
                        this.items = resp.data.Result.itemsDetails;
                    }
                });
        },

        rowClick: function (item, row) {
            this.selected = [item];
        },

        changeItem(item) {
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTotalNeto = 0;
            this.orderLocal.OrdTaxAmount = 0;

            let edit = Object.assign({},
                this.items.find((x) => x.CtdID == item.CtdID)
            );
            let indexEdit = this.items.indexOf(item);

            edit.OddAmount = edit.OddUnitPrice * edit.OddQuantity;

            this.items.splice(indexEdit, 1, edit);

            this.items.forEach((element) => {
                let unitPrice =
                    element.OddUnitPrice == null || element.OddUnitPrice == "" ?
                    0 :
                    element.OddUnitPrice;

                this.orderLocal.OrdTotalNeto =
                    parseFloat(this.orderLocal.OrdTotalNeto) +
                    parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

                this.orderLocal.OrdSubTotal =
                    parseFloat(this.orderLocal.OrdSubTotal) +
                    parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity)) +
                    (parseFloat(
                            this.orderLocal.OrdDirectCosts == "" ? 0 : this.orderLocal.OrdDirectCosts
                        ) +
                        parseFloat(
                            this.orderLocal.OrdGeneralgto == "" ? 0 : this.orderLocal.OrdGeneralgto
                        ) +
                        parseFloat(
                            this.orderLocal.OrdUtilities == "" ? 0 : this.orderLocal.OrdUtilities
                        ) -
                        parseFloat(this.orderLocal.OrdDiscount == "" ? 0 : this.orderLocal.OrdDiscount));
            });

            this.orderLocal.OrdTaxAmount =
                (parseFloat(this.orderLocal.OrdSubTotal) *
                    parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                100;

            this.orderLocal.OrdTotal =
                this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
        },

        changeTax(item) {
            if (this.tax != null) {
                this.orderLocal.OrdTaxPercentage = this.tax.DedHelper
                this.orderLocal.OrdTaxAmount =
                    (parseFloat(this.orderLocal.OrdSubTotal) *
                        parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                    100;

                console.log("", this.orderLocal.OrdTaxAmount);
            }
        },

        changeTotal() {

            this.orderLocal.OrdTotalNeto = 0;
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTaxAmount = 0;

            this.items.forEach((element) => {
                let unitPrice = element.OddUnitPrice == null ? 0 : element.OddUnitPrice;

                this.orderLocal.OrdTotalNeto =
                    parseFloat(this.orderLocal.OrdTotalNeto) +
                    parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

                this.orderLocal.OrdSubTotal =
                    parseFloat(this.orderLocal.OrdTotalNeto)
                    /* parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity))  */
                    +
                    (parseFloat(
                            this.orderLocal.OrdDirectCosts == "" ? 0 : this.orderLocal.OrdDirectCosts
                        ) +
                        parseFloat(
                            this.orderLocal.OrdGeneralgto == "" ? 0 : this.orderLocal.OrdGeneralgto
                        ) +
                        parseFloat(
                            this.orderLocal.OrdUtilities == "" ? 0 : this.orderLocal.OrdUtilities
                        ) -
                        parseFloat(this.orderLocal.OrdDiscount == "" ? 0 : this.orderLocal.OrdDiscount));
                this.orderLocal.OrdTaxAmount =
                    (parseFloat(this.orderLocal.OrdSubTotal) *
                        parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                    100;
            });

            this.orderLocal.OrdSubTotal = parseFloat(
                parseFloat(this.orderLocal.OrdSubTotal).toFixed(2)
            );
            this.orderLocal.OrdTaxAmount = parseFloat(
                parseFloat(this.orderLocal.OrdTaxAmount).toFixed(2)
            );

            this.orderLocal.OrdTotal =
                this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;

        },

        getConfigFiles() {
            _AttachConfiguration.list({
                TrsCode: "TrsCotization",
                CpyID: localStorage.getItem('CpyID')
            }, this.$fun.getUserID()).then((resp) => {
                this.itemsAttach = resp.data.Result;
            });
        },

        save() {

            let Ctp = this.cotizationLocal.itemsParticipant.find(x=>x.CtpRuc == this.user.GenSupplierCE.CtpRuc);
            let CtpID = Ctp !== null ? Ctp.CtpID : Ctp;

            if(this.user.GenSupplierCE == null){
                this.$fun.sweetAlert("[Error] Proveedor Inválido.", "warning")
                return
            }

            if(Ctp == null){
                this.$fun.sweetAlert("[Error] al Validar Participante.", "warning")
                return
            }

            this.orderLocal.CttID = this.cotizationLocal.CttID;
            this.orderLocal.CtpID = CtpID//this.user.GenSupplierCE.CtpID;
            this.orderLocal.CtpRuc = this.user.GenSupplierCE.CtpRuc;
            this.orderLocal.TypeCredit = this.objTypeCredit == null ? "" : this.objTypeCredit.DedValue;
            this.orderLocal.TypeCreditValue = this.objTypeCredit == null ? "" : this.objTypeCredit.DedHelper == "" ? 0 : this.objTypeCredit.DedHelper;
            this.orderLocal.TypePayment = this.objTypePayment == null ? "" : this.objTypePayment.DedValue;
            this.orderLocal.TypePaymentValue = this.objTypePayment == null ? "" : this.objTypePayment.DedHelper == "" ? 0 : this.objTypePayment.DedHelper;
            this.orderLocal.TypePercentaje = this.tax.DedValue;
            this.orderLocal.OrdTaxPercentage = this.tax.DedHelper;
            this.orderLocal.details = this.items;
            this.orderLocal.SecStatus = 1;
            this.orderLocal.UsrCreateID = this.$fun.getUserID();
            //this.orderLocal.Attach1 = this.attach1.name;

            //Validamos Campos
            var resp = _validaERP.valida({
                form: '#formsuppliercotization',
                items: {
                    TypeCredit: {
                        required: true,
                        value: this.orderLocal.TypeCredit,
                    },
                    TypePayment: {
                        required: true,
                        value: this.orderLocal.TypePayment,
                    },
                    PrvDeliveryTime: {
                        required: true,
                        min: 1,
                        value: this.orderLocal.PrvDeliveryTime,
                    },
                    PrvTimeGarantia: {
                        required: true,
                        value: this.orderLocal.PrvTimeGarantia,
                    },
                    TypeCurrency: {
                        required: true,
                        value: this.orderLocal.TypeCurrency,
                    },
                }
            })

            if (resp) {

                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i].OddQuantity > this.items[i].CtdQuantity) {
                        this.$fun.alert(
                            "Cantidad inválido, No puede ser mayor a la solicitada, " +
                            this.items[i].CtdDescription,
                            "warning"
                        );
                        return;
                    }

                    if (
                        this.items[i].OddQuantity > 0 &&
                        this.items[i].OddUnitPrice == 0
                    ) {
                        this.$fun.alert(
                            "Precio inválido. " + this.items[i].CtdDescription,
                            "warning"
                        );
                        return;
                    }
                }

                this.orderLocal.OrdSubTotal = Number.parseFloat(this.orderLocal.OrdSubTotal)
                this.orderLocal.OrdTaxAmount = Number.parseFloat(this.orderLocal.OrdTaxAmount)
                this.orderLocal.OrdTotalNeto = Number.parseFloat(this.orderLocal.OrdTotalNeto)
                this.orderLocal.OrdTotal = Number.parseFloat(this.orderLocal.OrdTotal)

                this.orderLocal.OrdSubTotal = this.orderLocal.OrdSubTotal.toFixed(2)
                this.orderLocal.OrdTaxAmount = this.orderLocal.OrdTaxAmount.toFixed(2)
                this.orderLocal.OrdTotal = this.orderLocal.OrdTotal.toFixed(2)
                this.orderLocal.OrdTotalNeto = this.orderLocal.OrdTotalNeto.toFixed(2)

                console.log("save", this.orderLocal);

                /* if (this.orderLocal.details.length > 0) {
                    this.orderLocal.details.forEach(element => {
                        element.OddUnitPrice = parseFloat(element.OddUnitPrice).toFixed(4)
                        element.OddAmount = parseFloat(element.OddAmount).toFixed(2)
                    });
                } */
                
                console.log('atacchhhh ', this.itemsAttach);
                this.$fun.uploadFiles(this.itemsAttach, 5)
                .then(resp => {
                    if(resp.status == 200)
                    {
                        
                        this.orderLocal.Attach1 =  resp.data[0].RqdAttach
                        if (this.orderLocal.OrdTotal > 0) {
                            this.$fun.sweetAlert("¿Grabar Cotizacion?", "question").then((val) => {
                                if (val.value) {
                                    _sCotization.saveprovider(this.orderLocal, this.$fun.getUserID()).then(resp => {
                                        if (resp.status == 200) {
                                            this.$fun.alert(
                                                "Datos guardados correctamente",
                                                "success",
                                                false,
                                                "center"
                                            );
                                            this.$router.go(0);
                                        }
                                    })
                                }
                            });
                        }
                    }
                });

                
            }
        },
    }
};
</script>
