import { render, staticRenderFns } from "./LgsCotizationHome.vue?vue&type=template&id=5014280e&scoped=true&"
import script from "./LgsCotizationHome.vue?vue&type=script&lang=js&"
export * from "./LgsCotizationHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5014280e",
  null
  
)

export default component.exports