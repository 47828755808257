import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";
import AuthService from "../services/AuthService";

import { children as general } from "@/router//general.js";
import { children as security } from "@/router//security.js";
import { children as logistics } from "@/router//logistics.js";
import { children as querymanager } from "@/router//querymanager.js";

Vue.use(VueRouter, {

    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },

});
let children = [];
children.push(...general);
children.push(...security);
children.push(...logistics);
children.push(...querymanager);


const routes = [{
        path: "*",
        redirect: "/micuenta",
    },
    {
        path: "/",
        name: "HOME",
        component: Home,
        meta: {
            authenticated: true,
            breadcrumb: [{ text: "Inicio", disabled: false, href: "/#/" }],
        },
        children: children,
    },
    {
        path: "/micuenta",
        name: "LOGIN",
        breadcrumb: [{
            text: "login",
            disabled: false,
            href: "login",
        }, ],
        component: () =>
            import ("../views/Auth/Login.vue"),
    },
    {
        path: "/login",
        name: "LOGIN2",
        breadcrumb: [{
            text: "login2",
            disabled: false,
            href: "login2",
        }, ],
        component: () =>
            import ("../views/Auth/LoginSupplier.vue"),
    },
];


const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {

    let activeSession = localStorage.getItem("UsrID") != null ? true : false;
    let authenticated = to.matched.some((record) => record.meta.authenticated);

    if (to.name.toUpperCase() != "HOME" && to.name.toUpperCase() != "LOGIN") {

        AuthService.userOption(localStorage.getItem("UsrID"), to.name).then((r) => {
            if (r.data.UsoID != null) {
                to.params.security = r.data;

                if (authenticated && !activeSession) next("micuenta");
                else if (!authenticated && activeSession) next("");
                else next();
            }
        });
    } else if (authenticated && !activeSession) next("micuenta");

    else if (!authenticated && activeSession) {
        next("");
    } else if (to.name.toUpperCase() == "HOME" && localStorage.getItem("UsrID") != null) {

        AuthService.userOption(localStorage.getItem("UsrID"), to.name).then((r) => {
            to.params.security = r.data;
            next();
        });
    } else next();
});
export default router;