import Service from "./Service";
const resource = "helper/";
import Vue from "vue";

export default {

    uploadfilelogistics(document, requestID, files, parameter) {
        return Service.post(resource + "uploadfilelogistics", files, {
            params: {
                PrsDocumentNumber: document,
                requestID: requestID,
                parameter: parameter,
            },
        });
    },

    uploadFile(files, parameter) {
        return Service.post(resource + "uploadfile", files, {
            params: {
                parameter: parameter
            },
        });
    },

    download(path, requestID, type = 1) {
        return Service.post(resource + "download", {}, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                path: path,
                requestID: requestID,
                type: type
            },
        });
    },

    /*   download(path, type = 1) {
          return Service.post(resource + "downloadfile", {}, {
              responseType: "blob",
              headers: {
                  "Content-Type": "application/json",
                  //"content-disposition": "Access-Control-Expose-Headers"
              },
              params: {
                  path: path,
                  type: type
              },
          });
      }, */
};