<template>
<div style="box-shadow: 1px 3px 4px 0px #9c9898;border-radius: 20px;">
    <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="errors" @close="processing=false;messageProcessing=''" />


    <v-footer style="border-radius: 5px; " dense flat class="body-2 font-weight-bold" :color="'#f5f5f5'">

        <h5 class="pt-1 capitalize"> {{title}}</h5>

        <v-spacer></v-spacer>
        <slot name="options"> </slot>



        <v-tooltip bottom="" v-if="download">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" x-small @click="$emit('download')">
                    <v-icon>mdi-cloud-download</v-icon>
                </v-btn>
            </template>
            <span>Descargar</span></v-tooltip>


        <v-tooltip bottom="" v-if="save">
            <template v-slot:activator="{ on }">
                <v-btn text small v-on="on" class="capitalize save" @click="$emit('save')">
                    <v-icon style="font-size:16px;">mdi-content-save</v-icon>
                </v-btn>
            </template>
            <span>Guardar</span>
        </v-tooltip>


        <v-tooltip bottom="" v-if="close">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small @click="$emit('close')" elevation="0">
                    <v-icon style="font-size:16px;">mdi-close</v-icon>
                    <!-- <i class="fa-solid fa-right-from-bracket"></i> -->
                    <!-- mdi-close -->
                </v-btn>
            </template>
            <span>Salir</span>
        </v-tooltip>

    </v-footer>
</div>
</template>

<script>
import _sApprovalTransaction from "@/services/Security/SecApprovalTransactionService";
export default {
    name: "vcHeader",
    props: {
        title: {
            type: String,
            default: "Vuetify Confirm Dialog Example",
        },

        noDark: {
            type: Boolean,
            default: true,
        },
        close: {
            type: Boolean,
            default: false,
        },
        importfile: {
            type: Boolean,
            default: false,
        },
        download: {
            type: Boolean,
            default: false,
        },

        //Eventos
        save: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        restore: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Boolean,
            default: false,
        },
        clear: {
            type: Boolean,
            default: false,
        },

        color: null,

        //Transacciones
        approve: {
            type: Boolean,
            default: false,
        },
        optionemit: {
            type: Boolean,
            default: false,
        },
        transactionApproved: {
            type: Object,
            default: null,
        },

    },

    data: () => ({
        dialogDissaproved: false,
        //Loading
        processing: false,
        messageProcessing: "",
        errors: [],
    }),
    mounted() {},
    methods: {
        
    },
    watch: {

    },
    computed: {
        colorToolbar() {
            return this.color == null ? localStorage.getItem('colorToolbar') : this.color;
        }
    },

};
</script>

<style lang="scss" scoped></style>
