import Service from "../Service";

const resource = "GenProject/";

import Vue from "vue";

export default {
	save(obj, requestID)
	{
		return Service.post(resource + "save", obj, {
			params: {}
		})
	},

	update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    },

    delete(obj, requestID) {
        return Service.delete(resource + "delete", {
            data: obj
        });
    },
	
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },

	getUrlSearchProject() {
        return Vue.prototype.$http.defaults.baseURL + "GenProject/list";
    },
}