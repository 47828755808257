<template>
<div>

    <!--   <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" persistent transition="dialog-bottom-transition">
            <lgs-cotization-supplier @close="dialog = false" :item="item" :user="user" />
        </v-dialog>
    </div> -->

    <div v-if="showUploadEdit">
        <v-dialog class="elevation-0" v-if="showUploadEdit" v-model="showUploadEdit" width="100%" persistent transition="dialog-bottom-transition" max-width="800px">
            <lgs-upload-attach @close="showUploadEdit = false" :item="item" />
        </v-dialog>
    </div>

    <v-row>
        <v-card style="margin-top: 10px;border-radius:10px;margin-top:-20px">
            <v-card-title style="
              margin-top: -10px;
              margin-bottom: -10px;
              font-family: Calibri;
              font-size: 24px;
            ">
                <h4 class="s-color-title text-center">Bienvenido al Portal de Carga de Documentos</h4>
            </v-card-title>
        </v-card>

        <!--  <div> -->
        <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
            <v-card-title class="ml-3" style="font-size:11px">
                <b>Leyenda</b>
                <v-divider vertical class="ml-2"></v-divider>
                <ul>
                    <li>
                        <v-icon color="red" style="font-size:16px;">
                            fas fa-car
                        </v-icon> = Compra
                    </li>
                    <li>
                        <v-icon color="green" style="font-size:16px;">
                            fas fa-street-view
                        </v-icon> = Servicio
                    </li>
                    <li>
                        <v-icon color="" style="font-size:14px;">
                            fa-solid fa-cloud
                        </v-icon> = Subir Adjuntos
                    </li>
                    <!--  <v-divider></v-divider> -->
                    <li>
                        <v-icon color="brown" @click="openFile({name: 'ManualProveedor.pdf'})">mdi-download</v-icon>= <a style="text-decoration:underline">Descargar Manual</a>
                    </li>
                </ul>
                <br>

            </v-card-title>
        </v-card>

        <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
            <div class="card-body">
                <h4 class="card-title">Listado de OC/OS</h4>
                <!-- <input type="text" placeholder="Search" class="form-control"> -->
            </div>

            <vc-crud ref="crudorderpurcharse" no-full :config="config" :filter="filter" title="Orden Compra" searchInput>
                <template v-slot:filter>
                    <v-container>
                        <v-row>

                            <v-col lg="3" md="4" cols="6" class="pt-0 pb-0">
                                <vc-date label="Fecha Inicio" v-model="filter.BeginDate" />
                            </v-col>
                            <v-col lg="3" md="4" cols="6" class="pt-0 pb-0">
                                <vc-date label="Fecha Fin" v-model="filter.EndDate" />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>

                <template v-slot:TypeOrder="{ row }">
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" :color="row.TypeOrder == 1 ? 'red' : 'green'" style="font-size: 14px">
                                {{ row.TypeOrder == 1 ? "fas fa-car" : "fas fa-street-view" }}
                            </v-icon>
                        </template>
                        <span>{{ row.TypeOrder == 1 ? "Compra" : "Servicio" }}</span>
                    </v-tooltip>
                </template>

                <template v-slot:upload="{ row }">
                    <v-btn title="Subir Files" elevation="0" style="text-transform:capitalize" small icon @click="showEdit(row)">
                        <v-icon style="font-size: 16px">fa-solid fa-cloud</v-icon>
                    </v-btn>

                </template>
                <template v-slot:adjuntos="{ row }">
                        <v-chip x-small dark :color="row.Files == 1 ? 'success' : 'error'">
                        {{row.Files == 1 ? 'Si' : 'No'}}
                        </v-chip>

                </template>
                <template v-slot:RegisterComp="{ row }">
                        <v-chip x-small dark :color="row.RegisterComp == 1 ? 'success' : 'error'">
                        {{row.RegisterComp == 1 ? 'Registrado' : 'Sin registrar'}}
                        </v-chip>

                </template>

                
            </vc-crud>

        </v-card>
    </v-row>
</div>
</template>

<script>
import _sOrderPurcharse from "@/services/Logistics/LgsOrderPurcharseService";
import _sGeneral from "@/services/HelperService.js";

import LgsCotizationSupplier from './LgsCotizationSupplier.vue';
import LgsUploadAttach from './LgsUploadAttach.vue';

export default {
    components: {
        LgsCotizationSupplier,
        LgsUploadAttach
    },
    props: {
        user: {
            type: Object,
            default: {}
        }
    },
    data() {

        return {
            item: {},
            showUploadEdit: false,
            filter: {
                SupCode: ""
            },
            config: {
                model: {
                    OrdID: "ID",
                    OrdDate: "date",
                    SecUpdate: "date",
                    TypeOrder: "",
                    upload: "",
                    adjuntos:"",
                    RegisterComp:""
                },
                service: _sOrderPurcharse,
                headers: [{
                        text: "ID",
                        value: "OrdID"
                    },
                    {
                        text: "Subir",
                        value: "upload",
                        sortable: false,
                    },
                    {
                        text: "Tipo",
                        value: "TypeOrder",
                        sortable: false,
                    },
                    {
                        text: "N° Documento.",
                        value: "OrdDocumentNumber"
                    },
                    {
                        text: "Fecha",
                        value: "OrdDate"
                    },
                    {
                        text: "Proveedor",
                        value: "SupName"
                    },
                    {
                        text: "Total (Inc. IGV)",
                        value: "OrdTotal"
                    },
                    {
                        text: "Creado Por",
                        value: "NtpFullName"
                    },
                    {
                        text: "Fecha Apro.",
                        value: "SecUpdate",
                        sortable: false,
                    },
                    {
                        text: "Estado",
                        value: "OrdStatusName"
                    },
                    {
                        text: "Adjuntos",
                        value: "adjuntos"
                    },
                    {
                        text: "Aceptado",
                        value: "RegisterComp"
                    },

                    /* {
                        text: "View Recep.",
                        value: "StuffOtt"
                    }, */
                ]
            },
        };
    },

    watch: {

        /* user(){
            console.log("user homee",this.user)
            this.filter.SupCode = this.user.LgsSupplier == null ? "" : this.user.LgsSupplier.ProviderRUC
        } */
    },

    created() {
        console.log("user homee", this.user)

        this.filter.SupCode = this.$fun.getUserName() //this.user.LgsSupplier == null ? "" : this.user.LgsSupplier.ProviderRUC
    },

    methods: {
        initialize() {

        },

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 4).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        showEdit(item) {
            this.item = item
            this.showUploadEdit = true
            console.log("edit", item)
        }

    },
};
</script>

<style lang="scss" scoped>

</style>
