<template>
<div class="app-header header-shadow">
    <div class="logo-src" />
    <div class="app-header__content">
        <div class="app-header-left">

            <!-- <SearchBox /> -->
        </div>
        <div class="app-header-right">
            <div class="header-dots">

                <!-- <b-dropdown toggle-class="p-0 mr-0 mt-7" menu-class="" variant="link">
                    <span slot="button-content">
                       
                        <div class="icon-wrapper icon-wrapper-alt rounded-circle" style="height: 40px;width: 40px;place-content: center;">
                         <div class="icon-wrapper-bg bg-danger">
                        </div>
                            <v-badge class="badge badge-dot badge-dot-lg badge-danger" dot color="error error-4" top right offset-x="1" offset-y="20">
                            </v-badge>
                            <v-icon style="margin-right: 30px;">mdi-bell-ring-outline</v-icon>
                        </div>
                    </span>
                    <button type="button" tabindex="0" class="dropdown-item">Menus</button>
                    <button type="button" tabindex="0" class="dropdown-item">Settings</button>
                    <h6 tabindex="-1" class="dropdown-header">Header</h6>
                    <button type="button" tabindex="0" class="dropdown-item">Actions</button>
                    <div tabindex="-1" class="dropdown-divider"></div>
                    <button type="button" tabindex="0" class="dropdown-item">Dividers</button>
                </b-dropdown> -->

            </div>
            <UserArea :configUser="configUser" :user="user" @logout="$emit('logout')" />
        </div>
    </div>
    <div class="app-header__mobile-menu">
        <div>
            <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active' : isOpen }" @click="toggleMobile('closed-sidebar-open')">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
    <div class="app-header__menu">
        <span>
            <button class="btn-icon btn-icon-only" variant="primary" size="sm" v-bind:class="{ 'active' : isOpenMobileMenu }" @click="toggleMobile2('header-menu-open')">
                <div class="btn-icon-wrapper">
                    <font-awesome-icon icon="ellipsis-v" />
                </div>
            </button>
        </span>
    </div>
</div>
</template>

<script>
import SearchBox from './Header/SearchBox';
import UserArea from './Header/HeaderUserArea';

import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faEllipsisV,
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

library.add(
    faEllipsisV,
);
export default {
    name: "Header",
    components: {
        SearchBox,
        UserArea,
        'font-awesome-icon': FontAwesomeIcon,
    },

    data() {
        return {
            isOpen: false,
            isOpenMobileMenu: false,
        }
    },
    props: {
        configUser: {
            type: Object,
            default: null,
        },
        user: {
            type: Object,
            default: {
                PrsDocumentNumber: ""
            },
        },
    },
    methods: {
        toggleMobile(className) {
            const el = document.body;
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },

        toggleMobile2(className) {
            const el = document.body;
            this.isOpenMobileMenu = !this.isOpenMobileMenu;

            if (this.isOpenMobileMenu) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
    }
};
</script>
