let children = [];

children.push({
    path: "/logistica/requerimiento",
    name: "LGS_REQUIREMENT_PURCHASE",
    component: () =>
        import ("../views/Logistics/LgsRequirement/LgsRequirement.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimiento", href: "/logistica/requerimiento" },
            /* { text: "Compras", href: "/logistica/requerimiento/compras" }, */
        ],
    },
});

children.push({
    path: "/logistica/articulos",
    name: "LGS_ARTICLE",
    component: () =>
        import ("../views/Logistics/LgsArticle/LgsArticle.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Artículos", href: "/logistica/articulos" },
        ],
    },
});

children.push({
    path: "/logistica/ordencompra",
    name: "LGS_ORDER_PURCHARSE",
    component: () =>
        import ("../views/Logistics/LgsOrderPurcharse/LgsOrderPurcharse.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Orden Compra", href: "/logistica/ordencompra" },
        ],
    },
});

children.push({
    path: "/logistica/ordenpedido/atencioncompra",
    name: "LGS_PURCHASE_ORDER_ATTENTION",
    component: () =>
        import ("../views/Logistics/LgsRequirementAttention/LgsRequirementAttention.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Ordenes Pedido", disabled: true },
            { text: "Atencion de Compra", href: "/logistica/ordenpedido/atencioncompra" },
        ],
    },
});

children.push({
    path: "/logistica/cotizaciones",
    name: "LGS_COTIZATIONS",
    component: () =>
        import ("../views/Logistics/LgsCotization/LgsCotization.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Cotizaciones", href: "/logistica/cotizaciones" },
        ],
    },
});

children.push({
    path: "/logistica/requerimientosalmacen",
    name: "LGS_REQUIREMENT_STORE",
    component: () =>
        import ("../views/Logistics/LgsRequirementStore/LgsRequirementStore.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimientos", href: "/logistica/requerimientosalmacen" },
        ],
    },
});

children.push({
    path: "/logistica/misrequerimientosalmacen",
    name: "LGS_MY_REQUIREMENT_STORE",
    component: () =>
        import ("../views/Logistics/LgsRequirementStore/LgsRequirementStoreList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Mis Requerimientos", href: "/logistica/misrequerimientosalmacen" },
        ],
    },
});

children.push({
    path: "/logistica/cotizacion/configuracion",
    name: "LGS_COTIZATION_CONFIGURATION",
    component: () =>
        import ("../views/Logistics/LgsCotization/LgsCotizationConfiguration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Cotización", href: "/logistica/cotizacion/configuracion" },
        ],
    },
});

children.push({
    path: "/logistica/conformidades",
    name: "LGS_ORDERATTENDED",
    component: () =>
        import ("../views/Logistics/LgsOrderPurcharseAttended/LgsOrderPurcharseAttended.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Conformidades", href: "/logistica/conformidades" },
        ],
    },
});




export { children }