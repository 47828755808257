<template>
<div>
    <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="errors" @close="processing=false;messageProcessing=''" />
    <!--  -->
    <!-- <vc-toolbar-crud v-if="!noToolbar" :approve="approve" @approved="approved" @disapproved="disapproved" :transactionApproved="transactionApproved" :title="title" :entity="row" :save="save" :add="add" :edit="edit" :remove="remove" :restore="restore" :close="close" :view="view" :searchInput="searchInput" :search="!noSearch" :searchBottom="searchBottom" @save="saveChange()" @add="$emit('add'); row = {}" @edit="$emit('edit', selected.length > 0 ? selected[0] : null)" @delete="deleteEvent($event)" @close="$emit('close')" @view="$emit('view')" @search="searchEvent($event)">
        <template v-slot:options>
            <slot name="options"> </slot>
        </template>

    </vc-toolbar-crud> -->

    <!-- <vc-header-crud v-if="!noToolbar" :approve="approve" @approved="approved" @disapproved="disapproved" :transactionApproved="transactionApproved" :title="title" :entity="row" :save="save" :add="add" :edit="edit" :remove="remove" :restore="restore" :close="close" :view="view" :searchInput="searchInput" :search="!noSearch" :searchBottom="searchBottom" @save="saveChange()" @add="$emit('add'); row = {}" @edit="$emit('edit', selected.length > 0 ? selected[0] : null)" @delete="deleteEvent($event)" @close="$emit('close')" @view="$emit('view')" @search="searchEvent($event)" style="box-shadow: 1px 3px 4px 0px #9c9898;border-radius: 20px;">
        <template v-slot:options>
            <slot name="options"> </slot>
        </template>
    </vc-header-crud> -->
    <!-- <v-card class="pl-2 pr-2 pb-3 mt-1" style="border-radius: 10px"> -->

    <v-card class="pl-2 pb-3 pr-2 mr-0 mt-1" style="border-radius: 6px">
        <div>
            <!-- <v-card outlined class="pb-3"> -->
            <v-row class="mb-2 pl-1 pr-1 pt-0" style="">
                <!-- <v-card outlined elevation="0" class="pb-4" v-if="row !=={}"> -->
                <slot :item="row"></slot>
                <!-- </v-card> -->
            </v-row>
            <!-- </v-card> -->
            <v-row style="margin:auto" class="mb-3 mt-0">
                <slot style="z-index: -999" name="filter"> </slot>
            </v-row>

        </div>

        <div>
                <!-- <v-divider v-if="searchInput"></v-divider> -->
                <v-card-title style="margin-top: -30px;" v-if="searchInput">
                    <h6>Buscar</h6>
                    <v-spacer></v-spacer>
                    <v-text-field clearable :append-icon="searchBottom ? '' : 'mdi-magnify'" label="Buscar" v-model="searchText" single-line hide-details @click="getAnswerHead()">
                        <template v-slot:append>
                    <v-btn icon x-small v-if="searchBottom" @click="getAnswerHead()">
                        <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                    </v-btn>
                </template>
                    </v-text-field>
                </v-card-title>

            <v-card elevation="" style="border-top: aliceblue;box-shadow: -13px 14px 20px -24px #839bd4;
    border-left-color: #e5e5e5;
    border-right-color: #e5e5e5;
    border-top-color: #e5e5e5;">

                <!-- <v-card rounded="" style="border-radius:10px">
                    <v-list>
                        <v-col lg="4" md="4" cols="12" style="padding-top:0px !important;padding-bottom:0px !important;">
                            <v-text-field label="Buscar" style="margin:5px;" class="s-toolbar-search" ref="buscar" v-model="searchText" :append-icon="searchBottom ? '' : 'mdi-magnify'" autofocus="" dense clearable hide-details>
                                <template v-slot:prepend>
                                    <v-btn icon x-small v-if="searchBottom" @click="getAnswer()">
                                        <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-list>
                </v-card> -->

                

                <v-data-table style="border-radius: 6px;" class="table-bordered" v-model="selected" :show-select="!singleRow" :disable-sort="!sortable" :hide-default-footer="noFooter" :height="$vuetify.breakpoint.xs ? 'auto' : height" :headers="config.headers" :items="desserts" @item-selected="selectedCheckSlot($event)" @toggle-select-all="toggleSelectAll" @dblclick:row="doubleClickFunc" @click:row="rowClick" :options.sync="options" :item-key="propID" :group-by="groupBy" :footer-props="{
                showCurrentPage: true,
                showFirstLastPage: true,
                itemsPerPageOptions: itemsPerPage,
              }" :server-items-length="total" :loading="loading" :single-expand="true" fixed-header="" dense loading-text="Cargando" no-data-text="Sin Datos">
                    <template v-for="p in getProps()" v-slot:[getNameSlot(p)]="{ item }">
                        <slot :name="p" :row="item">{{ formatItem(item[p], config.model[p]) }}
                        </slot>
                    </template>
                    <!-- <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText" />
                    </template> -->
                </v-data-table>
                <!-- <v-row class="text-center px-4 align-center" wrap>
                    <v-col class="text-truncate" cols="12" md="2">
                        Total {{ totalRecords }} records
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount">
                        </v-pagination>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-select dense :value="itemsPerPage" label="Filtros por pagina" @change="itemsPerPage = parseInt($event, 10)" :items="itemsPerPage">
                        </v-select>
                    </v-col>
                    <v-col cols="6" md="1">
                        <v-text-field v-model="page" label="Go to page" type="number" dense @input="page = parseInt($event, 10)"></v-text-field>
                    </v-col>
                </v-row> -->
            </v-card>

            <!--  <div class="card-body">
                <h4 class="card-title">Basic</h4>
                <input type="text" placeholder="Type to Search" class="form-control">               
            </div> -->
        </div>
    </v-card>
    <!-- </v-card> -->
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "SCrud",

    props: {

        title: {
            type: String,
            default: "Vuetify Confirm Dialog Example",
        },

        config: {
            type: Object,
            default: null,
        },

        filter: {
            type: Object,
            default: null,
        },
        height: {
            type: String,
            default: "auto",
        },
        singleRow: {
            type: Boolean,
            default: true,
        },
        approve: {
            type: Boolean,
            default: false,
        },
        sortable: {
            type: Boolean,
            default: false,
        },
        noFooter: {
            type: Boolean,
            default: false,
        },
        noToolbar: {
            type: Boolean,
            default: false,
        },

        noFull: {
            type: Boolean,
            default: false,
        },
        noSearch: {
            type: Boolean,
            default: false
        },

        rowDefault: {
            type: Boolean,
            default: true,
        }, //enviar false para que no se seleccione fila por defecto

        searchInput: {
            type: Boolean,
            default: false
        },

        searchBottom: {
            type: Boolean,
            default: false
        },
        deleteNoFunction: {
            type: Boolean,
            default: false
        },
        dialog: {
            type: Boolean,
            default: false,
        },

        //Eventos
        save: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        restore: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Boolean,
            default: false,
        },
        pdf: {
            type : Boolean,
            default : false
        },
        transactionApproved: {
            type: Object,
            default: null,
        },
        groupBy: null,

    },
    data: () => ({
        itemsPerPage: [5, 10, 25, 50, -1],
        row: {},
        total: 0,
        loading: true,
        parametersPagination: {
            filter: {
                CpyID: 0
            },
            draw: 1,
            start: 0,
            length: 10,
            order: [{
                dir: "asc",
                column: "",
            }, ],
            search: "",
        },
        desserts: [],
        options: {},
        selected: [],
        searchText: "",
        CpyID: 0,
        processing: false,
        messageProcessing: "",
        errors: [],
        alertType: true,
        /* search: "", */
    }),
    watch: {
        filter: {
            handler() {
                this.options.page = 1;
                this.selected = [];

                this.getDataFromApi().then((data) => {
                    this.desserts = data.items;
                    this.total = data.total;
                    if (this.desserts.length > 0) this.refresh(this.desserts[0]);
                });
            },
            deep: true,
        },

        options: {
            handler() {
                this.getDataFromApi().then((data) => {
                    this.desserts = data.items;
                    this.total = data.total;
                    if (this.desserts.length > 0) this.refresh(this.desserts[0]);
                });
            },
        },

        searchText() {
            if (!this.searchBottom)
                this.debouncedGetAnswer();
        },
    },
    methods: {

        getAnswerHead() {
            if (this.searchText == null) this.searchText = "";
            this.searchEvent(this.searchText)
        },

        //
        getAnswer() {
            //if (this.searchText == null) this.searchText = "";
        },
        getNameSlot(p) {
            return "item." + p;
        },

        getProps() {
            let r = [];
            for (var prop in this.config.model) r.push(prop);
            return r;
        },

        formatItem(value, prop) {

            let val = value;
            if (prop == "date") val = this.$fun.formatDateView(val);
            if (prop == "time") val = this.$fun.formatTimeView(val);
            if (prop == "datetime") val = this.$fun.formatDateTimeView(val);
            return val;
        },

        //Refresh para Filtros
        refresh(obj) {
            if (obj == null) {
                this.getDataFromApi().then((data) => {
                    this.desserts = data.items;
                    this.total = data.total;
                    if (this.desserts.length > 0) {
                        this.selected = [this.desserts[0]];
                        this.row = Object.assign({}, this.desserts[0]);
                        this.$emit("rowSelected", this.selected);
                    }
                });
            } else if (this.propID != null) {
                let ob = this.desserts.find((x) => obj[this.propID] == x[this.propID]);
                for (var prop in ob) {
                    ob[prop] = obj[prop];
                }

                if (this.rowDefault) {
                    this.selected = [ob];
                }

                this.row = Object.assign({}, ob);
                this.$emit("rowSelected", this.selected); //Cuando se carga por primera vez
            }
        },
        //Double Click
        doubleClickFunc: function (mouse, row) {
            this.$emit("doubleClick", row.item);
            this.$emit("dblClick", row.item);
        },

        //All Items
        toggleSelectAll({
            items,
            value
        }) {
            if (value) this.selected = items;
            else this.selected = [];
            this.$emit("rowSelected", this.selected);
        },

        //item
        selectedCheckSlot(obj) {
            let any = obj.item;
            if (
                this.selected.find((x) => {
                    return x == any;
                }) != null
            )
                this.selected = this.selected.filter((x) => {
                    return x != any;
                });
            else {
                this.selected.push(any);
                this.row = Object.assign({}, any);
            }
            this.$emit("rowSelected", this.selected);
        },

        //Fila
        rowClick: function (item, row) {

            if (this.singleRow) {
                this.selected = [item];
                this.row = Object.assign({}, item);
            } else {
                if (
                    this.selected.find((x) => {
                        return x == item;
                    }) != null
                )
                    this.selected = this.selected.filter((x) => {
                        return x != item;
                    });
                else {
                    this.selected.push(item);
                    this.row = Object.assign({}, item);
                }
            }
            this.$emit("rowSelected", this.selected); //Click en la Fila
        },

        cancelRequest() {
            this.request.cancel();
            this.request = null;
        },

        //Get Data
        getDataFromApi() {
            if (this.request) this.cancelRequest();
            const axiosSource = axios.CancelToken.source();

            this.request = {
                cancel: axiosSource.cancel,
                msg: "Loading...",
            };

            this.loading = true;
            return new Promise((resolve, reject) => {
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                this.parametersPagination.start =
                    (this.options.page - 1) * this.parametersPagination.length;
                this.parametersPagination.filter = this.filter;
                this.parametersPagination.length = itemsPerPage;
                if (this.options.sortBy.length > 0)
                    this.parametersPagination.order = [{
                        column: this.options.sortBy[0],
                        dir: this.options.sortDesc[0] ? "desc" : "asc",
                    }, ];

                this.config.service
                    .pagination(this.parametersPagination, this.$fun.getUserID(), axiosSource)
                    .then((response) => {

                        this.selected = [];
                        let items = response.data.data;

                        const total = response.data.recordsTotal;
                        setTimeout(() => {
                            this.loading = false;
                            resolve({
                                items,
                                total,
                            });
                        }, 0);
                    });
            });
        },

        searchEvent(search) {
            this.parametersPagination.search = search;
            this.options.page = 1;

            this.getDataFromApi().then((data) => {
                this.desserts = data.items;
                this.total = data.total;
                if (this.desserts.length > 0) this.refresh(this.desserts[0]);
            });
        },

        //Eventos de Save, Delete
        saveExecute() {
            return new Promise((resolve, reject) => {
                let objsave = this.row;
                let obj = Object.assign({}, this.row);
                let objService = {}

                objsave.CpyID = localStorage.getItem('CpyID');

                if (obj[this.propID] == 0 || obj[this.propID] == undefined) {
                    objsave.UsrCreateID = this.$fun.getUserID();
                    objService.service = this.config.service.save
                } else {
                    objsave.UsrUpdateID = this.$fun.getUserID();
                    objService.service = this.config.service.update
                }

                objService.service(objsave, this.$fun.getUserID()).then((response) => {
                        resolve(response);
                        if (response.status == 200) {
                            this.messageProcessing = "";
                            this.processing = false;
                            if (obj[this.propID] == 0 || obj[this.propID] == undefined) {
                                this.$fun.alert(this.$const.MSG_004, "success", this.alertType, localStorage.getItem('AlertAlign'));
                            } else {
                                this.$fun.alert(this.$const.MSG_005, "success", this.alertType, localStorage.getItem('AlertAlign'));
                            }
                            this.row = {}
                            this.refresh()
                            this.$emit('close')
                        }
                    },
                    (e) => {
                        let error = e.response.data.Errors.join(', ')
                        this.messageProcessing = error == null ? e.response.data.Message : error;
                        this.errors = e.response.data.Errors;
                        this.processing = true;

                        reject(e);
                    })

            })
        },

        saveChange() {
            this.row.save = () => {

                return new Promise((resolve, reject) => {
                    this.$fun.alert(this.$const.MSG_001, "question", this.alertType, localStorage.getItem('AlertAlign')).then((val) => {
                        if (val.value) {

                            this.messageProcessing = ""
                            this.processing = true

                            this.saveExecute().then((r) => {
                                resolve(r);
                            }, (e) => {
                                reject(e)
                            });
                        }

                    });
                })
            };
            this.$emit("save", this.row);
        },

        deleteEvent(value) {

            if (!this.deleteNoFunction) {
                let obj = Object.assign({}, this.row);

                obj.SecStatus = value;
                obj.UsrUpdateID = this.$fun.getUserID();
                obj.SecUpdate = this.$fun.getDate()

                if (obj[this.propID] > 0) {
                    let Text = this.$const.MSG_003;
                    this.$fun.alert(Text, "question", this.alertType, localStorage.getItem('AlertAlign')).then((val) => {
                        if (val.value) {
                            this.config.service.delete(obj, this.$fun.getUserID()).then((res) => {
                                this.refresh()
                                this.$emit('close')
                                this.$fun.alert(this.$const.MSG_005, "success");
                            });
                        }
                    });
                }

            } else this.$emit("delete", this.row);
        },

        //tramsaction
        approved() {
            this.$emit("approved");
        },

        disapproved() {
            this.$emit("disapproved");
        }
    },
    computed: {
        showLocal: {
            get: function () {
                this.processing = false
                this.row = this.data
                return this.dialog
            },
            set: function (value) {
                this.row = {}
                this.processing = false
                this.$emit('close')
            }
        },
        propID() {
            for (var prop in this.config.model)
                if (this.config.model[prop] == "ID") return prop;
            return null;
        },
    },

    created() {
        //this.CpyID = localStorage.getItem('CpyID')
        this.alertType = localStorage.getItem('AlertType') == 'true' ? true : false;

        if (this.noFull) this.itemsPerPage = [5, 10, 50, 200];
        let filterHeaders = [];
        this.config.headers.forEach((element) => {
            filterHeaders.push(element);
        });
        this.config.headers = filterHeaders;
        //this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
        this.debouncedGetAnswer = _.debounce(this.getAnswerHead, 700);
    },
    destroyed() {},
};
</script>

<!-- 

<style scoped>
.table {
    width: 100%;
    margin-bottom: 10rem;
    color: #212529;
}

.table th,
.table td {
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}
</style>
 -->
