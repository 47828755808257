<template>
<div style="padding-top:-2px;margin-top:-2px;">
    <template>
        <v-label>
            <b>{{ label }}</b>
        </v-label>
        <vc-select-generic ref='sgeneric' style="margin-top:-19px;" :config="config" :disabled="disabled" :readonly="readonly" :itemvalue="itemvalue" :add="add" :clearable="clearable" :itemtext="abbr ? 'DedAbbreviation' : 'DedDescription'" @add="addEvent()" :noDefault="clearable" :value="valueLocal" @input="input($event)" @change="change($event)" :returnObject="returnObject" :autofocus="autofocus" @updateData='$emit("updateData",$event)' :outlined="outlined" :autocomplete="autocomplete" :full="full" />
    </template>
</div>
</template>

<script>
import _sDefinition from "@/services/General/GenDefinitionService.js";

export default {
    components: {},
    props: {
        itemvalue: {
            type: String,
            default: "DedValue",
        },
        abbr: {
            type: Boolean,
            default: false,
        },
        value: null,
        label: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        def: {
            type: Number,
            default: 0,
        },
        add: {
            type: Boolean,
            default: false,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        dgr: {
            type: Number,
            default: 0,
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },

        outlined: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({
        valueRadio: null,
        /* selected: [], */
        valueLocal: null,
        dialogAdd: false,
        item: 0,
        items: [],
        config: {
            url: _sDefinition.definitiongroupURL(),
            title: "",
            params: {
                defID: 0,
                dgrID: 0,
                requestID: 0,
            },
        },
    }),

    watch: {
        error(msg) {
            this.$fun.alert(msg, "warning");
            this.focus();
            this.setColor("error");
        },

        dgr() {
            this.config.params.dgrID = this.dgr;
        },
        def() {
            this.config.params.defID = this.def;
        },
        /* selected(value) {
            let f = this.items.find((x) => x[this.id] == value);
            if (!this.returnObject) this.$emit("input", value);
            else this.$emit("input", f);
        }, */
        /* valueRadio(value) {
            this.$emit("input", value);
            this.$emit(
                "returnObject",
                this.items.find((x) => x[this.id] == value)
            );
        }, */

        value(value) {
            if (this.radio) this.valueRadio = value;
            else if (this.check) this.selected = value;
            else this.valueLocal = this.value;
        },
    },
    methods: {
        isFocused(val) {
            this.$refs.sgeneric.isFocused(val);
        },
        focus() {
            this.$refs.sgeneric.focus();
        },

        refresh(item) {
            this.$refs.sgeneric.load();
        },
        addEvent() {
            this.dialogAdd = true;
        },
        input(item) {
            this.$emit("input", item);
        },
        change(item) {
            this.$emit("change", item);
        },
    },
    created() {

        this.config.title = this.label;
        this.config.params.defID = this.def;
        this.config.params.dgrID = this.dgr;
        this.config.params.requestID = this.$fun.getUserID();
        this.valueLocal = this.value;
    },
};
</script>
