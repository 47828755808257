<template>
<div>
    <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="errors" @close="processing=false;messageProcessing=''" />

    <div v-if="dialogDissaproved">
        <v-dialog v-model="dialogDissaproved" width="400" persistent>
            <v-card>
                <vc-toolbar title="Motivo" close @close="dialogDissaproved=false"></vc-toolbar>
                <!-- <v-container> -->
                <v-row style="margin:auto">
                    <v-col cols="12">
                        <vc-textarea label="Motivo de Desaprobación" autofocus v-model="transactionApproved.AtsObservation" />
                    </v-col>
                    <v-col cols="12">
                        <v-btn color="primary" block small @click="disapproved()">Desaprobar</v-btn>
                    </v-col>
                </v-row>
                <!-- </v-container> -->
            </v-card>
        </v-dialog>
    </div>

    <v-toolbar class="tran" dense height="30" elevation="3" :color="colorToolbar" dark style="border-radius: 4px;font-family:Calibri;z-index: 999;">
        <v-toolbar-title> {{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <slot name="options"> </slot>

        <!-- //Inicio Botones -->
        <!-- //Transacciones -->
        <!-- <v-tooltip bottom="" v-if="approve">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small @click="approved()">
                    <v-icon color="info" style="font-size:16px;"> fa fa-repeat</v-icon>
                </v-btn>
            </template>
            <span>Solicitar Reajuste</span></v-tooltip> -->

        <v-tooltip bottom="" v-if="approve">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small @click="approved()">
                    <v-icon color="#daffdc" style="font-size:16px;"> far fa-thumbs-up</v-icon>
                </v-btn>
            </template>
            <span>Aprobar</span></v-tooltip>

        <v-tooltip bottom="" v-if="approve">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small @click="dialogDissaproved = true">
                    <v-icon color="red" style="font-size:16px;"> far fa-thumbs-down</v-icon>
                </v-btn>
            </template>
            <span>Desaprobar</span></v-tooltip>
        <!-- Fin Transacciones -->

        <v-tooltip bottom="" v-if="download">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" x-small @click="$emit('download')">
                    <v-icon>mdi-cloud-download</v-icon>
                </v-btn>
            </template>
            <span>Descargar</span></v-tooltip>

        <v-tooltip bottom="" v-if="add && $fun.getSecurity().IsAllowAdd">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small append elevation="0" @click="$emit('add')">
                    <v-icon style="font-size:18px;">mdi-plus-circle-outline</v-icon>
                </v-btn>
            </template>
            <span>Agregar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="save && $fun.getSecurity().IsAllowSave">
            <template v-slot:activator="{ on }">
                <v-btn text small v-on="on" class="capitalize save" @click="$emit('save')">
                    <v-icon style="font-size:16px;">mdi-content-save</v-icon>
                </v-btn>
            </template>
            <span>Guardar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="edit && $fun.getSecurity().IsAllowEdit">
            <template v-slot:activator="{ on }">
                <v-btn text small v-on="on" @click="$emit('edit')">
                    <v-icon style="font-size:14px;">mdi-file-edit-outline</v-icon>
                </v-btn>
            </template>
            <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="remove && $fun.getSecurity().IsAllowDelete">
            <template v-slot:activator="{ on }">
                <v-btn text small v-on="on" @click="$emit('delete', 0)">
                    <v-icon style="font-size:18px;">mdi-minus-circle-outline</v-icon>
                </v-btn>
            </template>
            <span>Eliminar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="view && $fun.getSecurity().IsAllowView">
            <template v-slot:activator="{ on }">
                <v-btn text small v-on="on" @click="$emit('view')">
                    <v-icon style="font-size:14px;">far fa-eye</v-icon>
                </v-btn>
            </template>
            <span>Ver</span>
        </v-tooltip>
        <v-tooltip bottom v-if="clear">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small @click="$emit('clear')" elevation="0">
                    <v-icon style="font-size:18px;"> mdi-broom</v-icon>
                </v-btn>
            </template>
            <span>Limpiar</span></v-tooltip>
        <!-- <v-divider vertical></v-divider> -->
        <v-tooltip bottom="" v-if="close">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small @click="$emit('close')" elevation="0">
                    <v-icon style="font-size:16px;">fa-light fa-right-from-bracket</v-icon>
                    <!-- <i class="fa-solid fa-right-from-bracket"></i> -->
                    <!-- mdi-close -->
                </v-btn>
            </template>
            <span>Salir</span>
        </v-tooltip>
        <!-- <v-divider vertical></v-divider> -->

        

        <v-tooltip bottom="" v-if="importfile">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small @click="$emit('importfile')" elevation="0">
                    <v-icon style="font-size:18px;">mdi-file-import</v-icon>
                </v-btn>
            </template>
            <span>Importar</span>
        </v-tooltip>

        <!--  //Fin Botones -->

    </v-toolbar>
</div>
</template>

<script>
import _sApprovalTransaction from "@/services/Security/SecApprovalTransactionService";
export default {
    name: "vcHeader",
    props: {
        title: {
            type: String,
            default: "Vuetify Confirm Dialog Example",
        },

        noDark: {
            type: Boolean,
            default: true,
        },
        close: {
            type: Boolean,
            default: false,
        },
        importfile: {
            type: Boolean,
            default: false,
        },
        download: {
            type: Boolean,
            default: false,
        },

        //Eventos
        save: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        restore: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Boolean,
            default: false,
        },
        clear: {
            type: Boolean,
            default: false,
        },

        color: null,

        //Transacciones
        approve: {
            type: Boolean,
            default: false,
        },
        optionemit: {
            type: Boolean,
            default: false,
        },
        transactionApproved: {
            type: Object,
            default: null,
        },

    },

    data: () => ({
        dialogDissaproved: false,
        //Loading
        processing: false,
        messageProcessing: "",
        errors: [],
    }),
    mounted() {},
    methods: {
        disapproved() {

            if (this.transactionApproved)

                this.$fun.sweetAlert("Seguro de desaprobar Documento?", "question").then((r) => {
                    if (r.value) {
                        this.messageProcessing = ""
                        this.processing = true
                        this.transactionApproved.AtsStatus = 3;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.messageProcessing = "";
                                this.processing = false;
                                this.$fun.sweetAlert("Documento desaprobado", "success");
                                this.$emit("close");
                            },
                            (e) => {
                                let errors = e.response.data.Errors
                                let message = e.response.data.Message
                                let error = e.response.data.errors
                                this.errors = errors == null ? error == null ? message == null ? "" : message : error.join(',') : errors.join(',');

                                this.messageProcessing = this.errors;
                                this.processing = true;
                            });
                    }
                });
            else this.$emit("disapproved");
        },
        approved() {
            /* console.log("approved", this.optionemit);
            if (this.optionemit == true) {
                console.log("optionemit", this.optionemit);
                this.$emit("approved");
                return
            } */

            if (this.transactionApproved && !this.optionemit) {
                console.log("transactionApproved", this.transactionApproved);

                this.$fun.sweetAlert("Seguro de aprobar Documento?", "question").then((r) => {
                    if (r.value) {
                        this.messageProcessing = ""
                        this.processing = true

                        this.transactionApproved.AtsStatus = 2;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.messageProcessing = "";
                                this.processing = false;
                                this.$fun.sweetAlert("Aprobado Correctamente", "success");
                                this.$emit("close");
                            },
                            (e) => {

                                let errors = e.response.data.Errors
                                let message = e.response.data.Message
                                let error = e.response.data.errors
                                this.errors = errors == null ? error == null ? message == null ? "" : message : error.join(',') : errors.join(',');

                                this.messageProcessing = this.errors;
                                this.processing = true;
                            });
                    }
                });
            } else this.$emit("approved");
        },
    },
    watch: {

    },
    computed: {
        colorToolbar() {
            return this.color == null ? localStorage.getItem('colorToolbar') : this.color;
        }
    },

};
</script>

<style>
.basil {
    background-color: #fffbe6 !important;
}

.font-20 {
    font-size: 18px !important;
    padding-right: 5px;
}

.save {
    margin-right: 7px;
}

.tran {
    opacity: 0.9 !important;

    /*background: rgba(76, 175, 80, 1)*/
}
</style>
