let children = [];
children.push({
    path: "/configuracion/usuario",
    name: "SEC_USER",
    component: () =>
        import ("../views/Security/UserOption/UserOption.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "seguridad", disabled: true },
            { text: "usuarios", href: "/configuracion/usuario" },
        ],
    },
});

children.push({
    path: "/configuracion/opcion",
    name: "SEC_OPTION",
    component: () =>
        import ("../views/Security/Option/OptOption.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Opción", href: "/configuracion/opcion" },
        ],
    },
});

children.push({
    path: "/configuracion/circuitosaprobacion",
    name: "SEC_APPROBALCIRCUIT",
    component: () =>
        import ("../views/Security/circuit/SecAutorize.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Circuitos Aprobacion", href: "/configuracion/circuitosaprobacion" },
        ],
    },
});

children.push({
    path: "/configuracion/permisocentrocosto",
    name: "SEC_COST_CENTER_PERMITION",
    component: () =>
        import ("../views/Security/CostCenter/SecCostCenterPermition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Permisos centro de costo", href: "/configuracion/permisocentrocosto" },
        ],
    },
});

children.push({
    path: "/configuracion/aprobaciones/requerimientos",
    name: "SEC_APPROVAL_REQUIREMENT",
    component: () =>
        import ("../views/Security/Approval/Requirement.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Aprobación Req.", href: "/configuracion/aprobaciones/requerimientos" },
        ],
    },
});

children.push({
    path: "/configuracion/aprobaciones/ordencompra",
    name: "SEC_APPROVAL_ORDER_PURCHARSE",
    component: () =>
        import ("../views/Security/Approval/OrderPurcharse.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Aprobación Orden Compra", href: "/configuracion/aprobaciones/ordencompra" },
        ],
    },
});

children.push({
    path: "/configuracion/circuitos/excepciones",
    name: "SEC_EXCEPTION_CIRCUIT",
    component: () =>
        import ("../views/Security/TransactionUserRequired/TransactionUserRequired.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Excepciones Circuitos", href: "/configuracion/circuitos/excepciones" },
        ],
    },
});

children.push({
    path: "/configuracion/transacciones",
    name: "SEC_TRANSACTION",
    component: () =>
        import ("../views/Security/Transaction/Transaction.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Transacciones", href: "/configuracion/transacciones" },
        ],
    },
});

children.push({
    path: "/configuracion/columnastransacción",
    name: "SEC_TRANSACTION_COLUMN_EXCEL",
    component: () =>
        import ("../views/Security/SecTransactionColumnExcel/SecTransactionColumnExcel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Columnas Transacción", href: "/configuracion/columnastransacción" },
        ],
    },
});

export {
    children
}