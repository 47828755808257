window.$ = window.jQuery = require("jquery");
import push from "push.js";

import Vue from 'vue'
import App from './Core.vue'
import router from './router'

//
import BootstrapVue from "bootstrap-vue"
import moment from "moment";
import VueMoment from "vue-moment";
import VueMask from "vue-the-mask";
import IdleVue from "idle-vue";
import _ from "lodash";
import CKEditor from 'ckeditor4-vue';


import ArgonDashboard from "./argon-dashboard";
var languaje = localStorage.getItem('languaje');

import store from "./store/";
import vuetify from "./plugins/vuetify.js";
import fun from "./plugins/fun";

if (languaje == "es") {
    import ("./plugins/const-es.js")
    .then((module) => {
        Vue.prototype.$const = module.default;
    });
} else {
    import ("./plugins/const-en.js")
    .then((module) => {
        Vue.prototype.$const = module.default;
    });
}

//import constapp from cons;
import base from "./plugins/base";
import axios from "./services/Service";

const eventsHub = new Vue();
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: store.state.TimeInactive,
    startAtIdle: false,
});


Vue.use(_);
Vue.use(VueMask);
/* Vue.use(BootstrapVue); */
Vue.use(ArgonDashboard);
Vue.use(CKEditor);

Vue.prototype.moment = moment;
/* Vue.prototype.$const = constapp; */
Vue.prototype.$fun = fun;
Vue.prototype.$push = push;
Vue.prototype.$http = axios;

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token != null) {
            config.headers.common["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {

        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }


    },
    (error) => {
        /*         console.log("error", error)
                console.log("response", error.response) */

        if (error.response == null) {
            fun.alertFull("Error de Conexión", "error");
            return;
        }

        if (error.response.status) {
            switch (error.response.status) {
                case 400:
                    fun.alert(error.response.data.Message, "warning");

                    break;
                case 401:

                    localStorage.setItem("isIdle", false);
                    document.onload = null;
                    document.onmousemove = null;
                    document.onmousedown = null;
                    document.ontouchstart = null;
                    document.onclick = null;
                    document.onscroll = null;
                    localStorage.clear();
                    localStorage.removeItem("token");
                    router.push("/micuenta");
                    if (localStorage.getItem("token") == null)
                    //  localStorage.clear();router.replace({ path: "/login",query: { redirect: router.currentRoute.fullPath },);
                        fun.alert("La sesión ha caducado.", "warning");
                    break;
                case 403:
                    fun.alert("403", "warning");
                    break;
                case 404:

                    if (localStorage.getItem("token") != null) {
                        fun.alert("El servicio solicitado no existe.");
                    } else {
                        router.push("/micuenta");
                    }
                    break;
                case 500:
                    // localStorage.clear();
                    // router.replace({
                    //   path: "/login",
                    //   query: { redirect: router.currentRoute.fullPath },
                    // });
                    if (localStorage.getItem("token") != null) {
                        fun.alert(error.response.data.ExceptionMessage, "error");
                    } else {
                        fun.alert("[IMP][Connection Failed]", "error");
                    }

                    localStorage.setItem("isIdle", false);
                    document.onload = null;
                    document.onmousemove = null;
                    document.onmousedown = null;
                    document.ontouchstart = null;
                    document.onclick = null;
                    document.onscroll = null;
                    localStorage.clear();
                    localStorage.removeItem("token");
                    router.push("/micuenta");

                    break;
                case 502:
                    if (localStorage.getItem("token") != null) fun.alert(error.response.data.Message, "error");
                    break;
            }
            return Promise.reject(error);
        }
    }
);
// Load Locales ('en' comes loaded by default)
require("moment/locale/es");

// Choose Locale
moment.locale("es");

Vue.use(VueMoment, { moment });
Vue.directive("focus", {
    inserted: function(el) {
        el.firstChild.firstChild.childNodes[1].childNodes[1].focus();
    },
});

Vue.config.productionTip = false;

/* new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app"); */
new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");