<template>
	<div>
		<v-card>
			<vc-toolbar
				title="Importación de cotización"
			></vc-toolbar>
			<v-container>
				<v-row>
					<v-col>
						<vc-import-excel
							:verify="true"
							@verifyRules="verifyRules($event)"
							@clear="clear()"
						>

						</vc-import-excel>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dataExcel: []
			}
		},

		methods: {
			clear()
			{
				this.dataExcel = [];
			},

			verifyRules(items) 
			{
				if(
					items[0]["DESCRIPCION"] == undefined ||
					items[0]["UNIDAD MEDIDA"] == undefined ||
					items[0]["CANTIDAD"] == undefined ||
					items[0]["PRECIO UNIDAD SIN IGV"] == undefined ||
					items[0]["PRECIO NETO"] == undefined ||
					items[0]["COMENTARIO"] == undefined
				)
				{
					this.$fun.alert("Formato de excel invalido", "warning");
					this.items = [];
					this.dataExcel = [];
					return;
				}
				this.$emit("getDataExcel", items)
				this.items = [];
			}
		},
	}
</script>
