<template>
<div>
    <v-dialog v-if="showLocal" v-model="showLocal" :persistent="messageProcessing.length==0" width="400">
        <v-row style="margin:auto">

            <v-card color="white" dark>
                <v-progress-linear model-value="10" striped indeterminate color="light-blue" class="mb-0 mt-0"></v-progress-linear>
                <v-card-text v-if="messageProcessing.length == 0">
                    <!-- <v-progress-linear indeterminate color="blue" class="mb-2 mt-7"></v-progress-linear> -->
                    <!--
                <h2 style="color:black;text-align: center" class="pt-5">Procesando</h2>
                <h4 style="color:black;text-align: center" class="pt-1">Por favor espere...</h4> -->
                    <!-- <v-img class="mt-4" style="margin-left: 20%;" src="../../assets/images/loader.gif" height="200" width="200"></v-img> -->
                    <h5 style="color:black;text-align: center;margin-top:-10px" class="pt-0">Procesando</h5>
                    <h6 style="color:black;text-align: center;margin-bottom:-10px" class="pt-0">Por favor espere...</h6>
                </v-card-text>
                <v-card-text v-else>
                    <div class="pt-4" style="color: black">
                        <!-- <v-progress-linear indeterminate color="red" class="mb-2 mt-1"></v-progress-linear> -->
                        <h5>Errores encontrados: </h5>
                        <br />
                        <!-- {{ messageProcessing }} -->
                        <!-- <ul v-for="item in errors" :key="item"> -->
                        {{errors}}
                        <!-- <li v-for="item in errors" :key="item">
                            {{item}}
                        </li> -->
                        <!-- </ul> -->
                    </div>
                </v-card-text>
                <v-progress-linear model-value="100" striped color="light-blue"></v-progress-linear>

            </v-card>
        </v-row>
    </v-dialog>
</div>
</template>

<script>
export default {
    props: {
        processing: {
            type: Boolean,
            default: false
        },
        messageProcessing: {
            type: String,
            default: ""
        },
        errors: null
    },
    data: () => ({

    }),

    computed: {
        showLocal: {
            get: function () {
                return this.processing
            },
            set: function (value) {
                this.$emit('close')
            }
        },
    },

};
</script>

<style lang="scss" scoped>

</style>
