<template>
<div class="d-flex">

    <div class="header-btn-lg pr-0">
            <!-- <h5>{{user.NtpFullName}}</h5> -->
        <div class="widget-content p-6">
            <div class="widget-content-wrapper">
                <div class="widget-content-left  ml-3">
                
                    <!-- <div class="widget-heading">
                        <v-menu bottom offset-y transition="slide-y-transition" :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" small @click="clickNotification()">
                                    <v-badge dot color="error error-4" bottom left offset-x="5" offset-y="0">
                                    </v-badge>
                                    <v-icon>mdi-bell-ring-outline</v-icon>
                                </v-btn>
                            </template>

                        </v-menu>
     
                    </div> -->
                </div>
                <div class="widget-content-left  ml-3 header-user-info">
                    {{user.NtpName}}
                    <!-- <div class="widget-heading">{{user.LgsSupplier !== null ? user.LgsSupplier.ProviderName == undefined? "" : user.LgsSupplier.ProviderName : user.UsrName}}</div> -->
                    <!-- <div class="widget-heading" v-else>{{user !== null ? user.NtpFullName : user.UsrName}}</div> -->
                </div>
                <div class="widget-content-left  ml-3">
                    <div class="widget-heading">
                        <v-menu bottom offset-y :close-on-content-click="false" transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" small>
                                    <v-icon x-small>fas fa-cogs</v-icon>
                                </v-btn>
                            </template>
                            <configuration-tool :configUser="configUser" :user="user" @logout="$emit('logout')"></configuration-tool>
                        </v-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ConfigurationTool from '@/views/Configurations/ConfigurationTool.vue'
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleDown,
    faCalendarAlt,
    faTrashAlt,
    faCheck,
    faFileAlt,
    faCloudDownloadAlt,
    faFileExcel,
    faFilePdf,
    faFileArchive,
    faEllipsisH,
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

library.add(
    faAngleDown,
    faCalendarAlt,
    faTrashAlt,
    faCheck,
    faFileAlt,
    faCloudDownloadAlt,
    faFileExcel,
    faFilePdf,
    faFileArchive,
    faEllipsisH,
);

export default {

    components: {
        ConfigurationTool,
        'font-awesome-icon': FontAwesomeIcon,
    },
    props: {
        configUser: {
            type: Object,
            default: null,
        },
        user: {
            type: Object,
            default: {
                PrsDocumentNumber: ""
            },
        },
    },
    data: () => ({

    }),

    mounted(){

    },

    methods: {

    }
}
</script>
