<template>
<v-app class="scroll">
    <div class="app-container app-theme-white">
        <transition name="fade" mode="out-in" appear>
            <Header :configUser="configUser" :user="user" @logout="logout()" />
        </transition>
        <transition name="fade" mode="out-in" appear>
            <div class="app-sidebar sidebar-shadow" @mouseover="toggleSidebarHover('add', 'closed-sidebar-open')" @mouseleave="toggleSidebarHover('remove', 'closed-sidebar-open')">
                <div class="app-header__logo">

                    <div class="logo-src" />

                    <div class="header__pane ml-auto">
                        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active': isOpen }" @click="toggleBodyClass('closed-sidebar')">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="app-sidebar-content">
                    <VuePerfectScrollbar class="app-sidebar-scroll">
                        <sidebar-menu style="    font-family: Calibri;font-size: 14px;" showOneChild :menu="userMenu" v-if="!usrExtern" />
                    </VuePerfectScrollbar>
                </div>
            </div>
        </transition>
        <div class="app-main__outer">
            <div class="app-main__inner">
                <v-main>
                    <v-container fluid>
                        <!-- <breadcrumb></breadcrumb> -->
                        
                        <page-title :icon="icon" v-if="!usrExtern"></page-title>
                        <sup-home v-if="usrExtern" :user="user"  @logout="logout()"/>
                        <router-view />
                    </v-container>
                </v-main>
            </div>
            <transition name="fade" mode="out-in" appear>
                <Footer />
            </transition>
        </div>
    </div>
</v-app>
</template>

<script>
import Header from "@/components/Layout/Header";
import Footer from "@/components/Layout/Footer";
import Breadcrumb from "@/components/Layout/Breadcrumb.vue";
import {
    SidebarMenu
} from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

//Services
import _sAuth from "@/services/AuthService";
import PageTitle from "../components/Layout/PageTitle.vue";
import SupHome from "@/views/Logistics/LgsCotizationSupplier/LgsCotizationHome.vue";

export default {
    name: "App",
    components: {
        Header,
        Footer,
        Breadcrumb,
        SidebarMenu,
        VuePerfectScrollbar,
        PageTitle,
        SupHome,
    },
    data() {
        return {
            usrName: "",
            user: {},
            userMenu: [],
            configUser: {},
            usrID: this.$fun.getUserID(),
            menu: [],
            isOpen: false,
            sidebarActive: false,
            collapsed: true,
            windowWidth: 0,
            user: {},
            icon: "pe-7s-home icon-gradient bg-amy-crisp",
            usrExtern: false,
            timeInactiveShow: 0,
            timeToken: null,
            isInactive: false,
        };
    },
    created() {
        this.usrName = localStorage.getItem("UsrName");
        this.usrExtern = localStorage.getItem("extern");
    

        this.initialize();
        this.configurateActivity();
    },
    methods: {
        initialize() {
            _sAuth.initialize(this.usrID).then((response) => {
                this.userMenu = response.data.UserMenu;

                this.user = response.data.User;

                console.log("response.data",response.data)

                this.userSAP = response.data.UserSAP;
                this.configUser = response.data.UserConfig;
                localStorage.setItem("UserInfo", JSON.stringify(response.data.User));
                localStorage.setItem(
                    "UserSAPInfo",
                    JSON.stringify(response.data.UserSAP || {})
                );
            });
        },

        logout() {
            clearInterval(this.timeToken);
            let extern = localStorage.getItem("UsrExtern") == "true" ? true : false;
            localStorage.setItem("isIdle", false);
            document.onload = null;
            document.onmousemove = null;
            document.onmousedown = null;
            document.ontouchstart = null;
            document.onclick = null;
            document.onscroll = null;
            localStorage.clear();
            localStorage.removeItem("token");
            extern = null;
            this.$router.push("/micuenta");
        },

         configurateActivity() {

            this.timeToken = setInterval(() => {
                if (this.usrID > 0) {
                    let time = localStorage.getItem("TimeTokenCount") - 3000;
                    localStorage.setItem("TimeTokenCount", time);

                    if (localStorage.getItem("TimeTokenCount") < 1) {
                        if (localStorage.getItem("token") != null) {

                            //   this.$fun.alert('Refrescando token','success');
                            _sAuth.refreshToken(this.usrID).then((r) => {
                                localStorage.setItem("TimeTokenCount", 0);
                                localStorage.setItem("TimeTokenCount", localStorage.getItem("TimeToken"));
                                localStorage.setItem("token", "");
                                localStorage.setItem("token", r.data.token);

                            });
                        }
                    } // else clearInterval(this.timeToken);
                }
            }, 3000);

            var t;

            let logoutTimeInactive = () => {
                localStorage.setItem("isIdle", true);
                clearInterval(t);
            };

            let resetTimer = () => {

                clearInterval(t);
                localStorage.setItem("isIdle", false);
                this.timeInactiveShow = 0;
                t = setInterval(() => {
                    if (this.timeInactiveShow < localStorage.getItem("TimeToken")) {
                        this.timeInactiveShow = this.timeInactiveShow + 1000;
                    } else {
                        localStorage.setItem("isIdle", true);
                        this.isInactive = true
                        logoutTimeInactive();
                    }
                }, 1000);
            };

            resetTimer();
            document.onload = resetTimer;
            document.onmousemove = resetTimer;
            document.onmousedown = resetTimer;
            document.ontouchstart = resetTimer;
            document.onclick = resetTimer;
            document.onscroll = resetTimer;
        },

        toggleBodyClass(className) {
            const el = document.body;
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        toggleSidebarHover(add, className) {
            const el = document.body;
            this.sidebarActive = !this.sidebarActive;

            this.windowWidth = document.documentElement.clientWidth;

            if (!this.usrExtern) {
                if (this.windowWidth > "992") {
                    if (add === "add") {
                        el.classList.add(className);
                    } else {
                        el.classList.remove(className);
                    }
                }
            }
        },
        getWindowWidth() {
            const el = document.body;

            this.windowWidth = document.documentElement.clientWidth;

            if (!this.usrExtern) {
                if (this.windowWidth < "1350") {
                    el.classList.add("closed-sidebar", "closed-sidebar-md");
                } else {
                    el.classList.remove("closed-sidebar", "closed-sidebar-md");
                }
            }
        },

        closeSidebar() {
            const el = document.body;
            this.windowWidth = document.documentElement.clientWidth;

            if (this.usrExtern) {
                el.classList.add("closed-sidebar", "closed-sidebar-md");
            } else {
                el.classList.remove("closed-sidebar", "closed-sidebar-md");
            }
        },
    },
    watch: {
        $route(to, from) {},
    },

    mounted() {
        this.$nextTick(function () {
            window.addEventListener("resize", this.getWindowWidth);

            //Init
            this.getWindowWidth();
            this.closeSidebar();
        });
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.getWindowWidth);
    },

     computed: {
        timeShow() {
            return (
                parseInt(
                    Math.round(
                        (localStorage.getItem("TimeToken") - this.timeInactiveShow) / 60000
                    )
                ).toString() + " min"
            );
        },
        isIdle() {
            /* return localStorage.isIdle; */
            return localStorage.getItem("isIdle");
        },
    },
};
</script>
