<template>
<div>

    
    <v-card elevation="0" width="300">
        <v-card-title>
            Configuración
        </v-card-title>

        <v-tabs background-color="transparent" grow v-model="tab">
            <v-tab href="#tab-0">
                General
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-0'">
                <v-card outlined class="text-center">
                    <v-list class="mx-auto">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="headline">
                                    {{ user.NtpName}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>
 
                    <v-card-actions>

                        <v-btn elevation="0" small color="primary" block @click="logout">
                            Cerrar sesión
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-tab-item>
        </v-tabs-items>

    </v-card>
</div>
</template>

<script>

export default {
    components: {
        
    },

    props: {
        configUser: {
            type: Object,
            default: null,
        },
        user: {
            type: Object,
            default: {
                PrsDocumentNumber: ""
            },
        },
    },
    data: () => ({
        tab: null,
        dialog: false,
        panel: [],
        mode: "hexa",
        generatetext: "",
        navtext: "",
        configurationUser : {}
    }),

    mounted() {

    },
    created() {
        this.configurationUser = this.configUser;
        this.generatetext = this.configUser.CfgToolbar
    },

    methods: {

        logout() {
            this.$emit("logout");
        },

    },
};
</script>
