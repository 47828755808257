import swal from "sweetalert2";
import router from "../router";
import moment from "moment";
import _fun from "@/plugins/fun";
import _sHelper from "@/services/HelperService.js";

var _constapp = null;

var languaje = localStorage.getItem('languaje');
if (languaje == "es") {
    import ("./const-es.js")
    .then((module) => {
        _constapp = module.default
    });
} else {
    import ("./const-en.js")
    .then((module) => {
        _constapp = module.default
    });
}
export default {

    /* **************************************************************************
     * Contiene las funciones javascript comunes para el proyecto,
     * todos las funciones empiezan con el prefijo "g"
     ****************************************************************************/
    alertFull(msg, type, subtitle) {
        swal.fire({
            title: msg,
            icon: type,
            allowOutsideClick: false,
        });
    },
    alert(msg, type, toa = true, position = 'top') {
        type = type || "info";
        var timer = 2000;
        var ok = false;
        var cancel = false;
        switch (type) {
            case "error":
                var timer = null;
                ok = true;
                break;
            case "warning":
                var timer = 3000;
                break;
            case "info":
                var timer = 3000;
                break;
            case "question":
                var timer = null;
                ok = true;
                cancel = true;
                break;
        }
        var toast = null;

        toast = swal.mixin({
            toast: toa,
            position: position,
            showConfirmButton: ok,
            showCancelButton: cancel,
            timer: timer,
        })

        return toast.fire({
            icon: type,
            title: msg,
        });
    },

    sweetAlert(msg, type) {
        return new Promise((resolve, reject) => {
            _fun.alert(msg, type, localStorage.getItem('AlertType'), localStorage.getItem('AlertAlign')).then((val) => {
                resolve(val);
            }, (e) => {
                reject(e)
            });
        })
    },
    getUserID() {
        return localStorage.UsrID;
    },

    getUserName() {
        return localStorage.UsrName;
    },

    getDate() {
        return moment().format(_constapp.FormatDateDB);
    },

    getUserInfo() {
        return JSON.parse(localStorage.UserInfo);
    },

    getSecurity() {
        return router.history.current.params.security;
    },
    isAdmin() {
        return router.history.current.params.security.IsLevelAdmin;
    },

    formatDateView(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatDateDB).format(_constapp.FormatDateView);
        }
        return val;
    },
    formatTimeView(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatTimeDB).format(_constapp.FormatTimeView);
        }
        return val;
    },
    formatDateTimeView(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatDateTimeDB).format(_constapp.FormatDateTimeView);
        }
        return val;
    },
    isEmptyVal: (val) => {
        var is = val === undefined || val === null || val === "";
        if (!is) is = val.length == 0;
        return is;
    },

    gTrim(cadena) {
        return cadena.replace(/^\s+|\s+$/g, "");
    },
    gRound(numero, decimales) {
        decimales = (!decimales ? 2 : decimales);
        var n = Math.round(numero * Math.pow(10, decimales)) / Math.pow(10, decimales);
        var nn = n.toFixed(decimales);
        return nn;
    },

    gValidarEmail(email) {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(email)) {
            swal('',
                'La dirección de email es incorrecta.',
                'warning'
            );
            return false;
        }
        return true;
    },
    gRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },


    //Validar Archivos
    isValidExtension(items) {
        var isValid = false

        for (let index = 0; index < items.length; index++) {

            if (items[index].RqdRequired == 1 && items[index].RqdAttach == null) {
                alert(items[index].RqdDescription + '. Obligatorio');
                items[index].RqdAttach = null
                isValid = true
            }

            console.log(items[index].RqdAttach)

            if (items[index].RqdAttach !== null && items[index].RqdAttach !== undefined) {
                let archivo = items[index].RqdAttach.name
                if (archivo !== null) {
                    var extension = archivo.substring(archivo.lastIndexOf('.'), archivo.length);
                    if (items[index].RqdAccept.split(',').indexOf(extension) < 0) {
                        _fun.alert(items[index].RqdDescription + '. Archivo inválido. No se permite la extensión ' + extension, "warning");
                        items[index].RqdAttach = null
                        isValid = true
                    }
                }
            }

        }
        return isValid
    },

    isValidSize(items) {
        var isValid = false
        for (let index = 0; index < items.length; index++) {
            if (items[index].RqdAttach !== null && items[index].RqdAttach !== undefined) {
                if (items[index].RqdAttach.size > items[index].RqdSize) {
                    _fun.alert(items[index].RqdDescription + ". El tamaño del archivo debe ser inferior a " + items[index].RqdSize / 1048576 + " MB!", "warning")
                    isValid = true

                }
            }
        }
        return isValid
    },

    isValidLong(items) {
        var isValid = false
        for (let index = 0; index < items.length; index++) {
            if (items[index].RqdAttach !== null && items[index].RqdAttach !== undefined) {
                if (items[index].RqdAttach.name.length > items[index].RqdLength) {
                    _fun.alert(items[index].RqdDescription + ". El tamaño del nombre de archivo debe ser inferior a " + items[index].RqdLength + " carácteres.", "warning")
                    isValid = true

                }
            }
        }
        return isValid
    },

    uploadFiles(itemsAttach, parameter) {
        let files = [];
        console.log("uploadFiles", itemsAttach);

        for (let index = 0; index < itemsAttach.length; index++) {
            if (itemsAttach[index].RqdAttach !== null) {
                files.push({
                    RqdID: itemsAttach[index].RqdID,
                    RqdAttach: itemsAttach[index].RqdAttach
                })
            }
        }
        if (_fun.isValidExtension(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        if (_fun.isValidSize(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        if (_fun.isValidLong(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        var formData = new FormData();
        files.forEach((element) => {
            formData.append(element.RqdID, element.RqdAttach);
        });

        return new Promise((resolve, reject) => {
            _sHelper.uploadfilelogistics("123", _fun.getUserID(), formData, parameter).then((resp) => {
                resolve(resp);
            }, (e) => {
                reject(e)
            });
        })
    },

    downloadFile(file, type, filename) {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        //link.setAttribute("download", filename + "_" + Math.floor(Math.random() * 999999 + 1) + "." + type.Extension); //or any other extension
        link.setAttribute("download", filename + "." + type.Extension); //or any other extension
        document.body.appendChild(link);
        link.click();
    },

    download(file, filename) {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    },
}