import Service from "../Service";

const resource = "orderpurchase/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationbyruc", parameters, {
            params: { requestID: requestID },
        });
    },
};