import Service from "../Service";

const resource = "cotization/";

export default {
    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: { requestID: requestID },
        });
    },




};